import React, { useState } from 'react';

function SignUp() {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <div class="container">
                <div class="row align-items-center justify-content-center height-self-center">
                    <div class="col-lg-5">
                        <div class="card auth-card">
                            <div class="card-body p-0">
                            <div class="col-lg-5 text0center mx-auto mt-3">
                                        <img src="../assets/images/page-img/green-mark-logo.png" class="img-fluid image-right" alt="" />
                                    </div>
                                <div class="d-flex align-items-center auth-content">
                                    <div class="col-lg-12 align-self-center">
                                        <div class="p-3">
                                            <div className='text-center mx-auto my-4'>
                                            <h2 class="mb-4 text-primary">Sign Up</h2>
                                            </div>
                                            <form>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <div class="floating-label form-group">
                                                            <input class="floating-input form-control" type="text" placeholder=" " required />
                                                            <label>First Name</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="floating-label form-group">
                                                            <input class="floating-input form-control" type="text" placeholder=" " required />
                                                            <label>Last Name</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="floating-label form-group">
                                                            <input class="floating-input form-control" type="email" placeholder=" " required />
                                                            <label>Email</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="floating-label form-group">
                                                            <input class="floating-input form-control" type="number" placeholder=" " required />
                                                            <label>Phone No.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div className="floating-label form-group position-relative">
                                                            <input className="floating-input form-control pr-4" type={showPassword ? 'text' : 'password'} placeholder=" " required />
                                                            <span className="show-pass eye position-absolute" onClick={togglePasswordVisibility}>
                                                                {showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                                            </span>
                                                            <label>Password</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div className="floating-label form-group position-relative">
                                                            <input className="floating-input form-control pr-4" type={showPassword ? 'text' : 'password'} placeholder=" " required />
                                                            <span className="show-pass eye position-absolute" onClick={togglePasswordVisibility}>
                                                                {showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                                            </span>
                                                            <label>Confirm Password</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck1" required />
                                                            <label class="custom-control-label" for="customCheck1">I agree with the terms of use</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='text-center mx-auto my-2'>
                                                <button type="submit" formAction='/' class="btn btn-block btn-primary">Sign Up</button>
                                                </div>
                                                <p class="mt-4">
                                                    Already have an Account ? <a href="/" class="text-secondary">Sign In</a>
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;