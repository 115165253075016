import React, { useState } from 'react';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';
import { useNavigate } from 'react-router-dom';

function InventoryDetails() {
  const [updateType, setUpdateType] = useState('quantity');
  const [formData, setFormData] = useState({
    date: '',
    status: '',
    referenceNumber: '',
    createdBy: '',
  });
  const [quantityRows, setQuantityRows] = useState([]);
  const [priceRows, setPriceRows] = useState([]);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editingRowData, setEditingRowData] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateTypeChange = (e) => {
    setUpdateType(e.target.value);
  };

  const handleTableInputChange = (e) => {
    const { name, value } = e.target;
    setEditingRowData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addRow = () => {
    const newRow = updateType === 'quantity'
      ? { product: '', currentQuantity: '', updatedQuantity: '' }
      : { product: '', currentPrice: '', updatedPrice: '' };
    if (updateType === 'quantity') {
      setQuantityRows([...quantityRows, newRow]);
    } else {
      setPriceRows([...priceRows, newRow]);
    }
    setEditingRowIndex(updateType === 'quantity' ? quantityRows.length : priceRows.length);
    setEditingRowData(newRow);
  };

  const handleEditRow = (index, rowData) => {
    setEditingRowIndex(index);
    setEditingRowData(rowData);
  };

  const saveRow = () => {
    const updatedRows = updateType === 'quantity' ? [...quantityRows] : [...priceRows];
    updatedRows[editingRowIndex] = { ...editingRowData };
    if (updateType === 'quantity') {
      setQuantityRows(updatedRows);
    } else {
      setPriceRows(updatedRows);
    }
    setEditingRowIndex(null);
    setEditingRowData({});
  };

  const deleteRow = (index) => {
    if (updateType === 'quantity') {
      setQuantityRows(quantityRows.filter((_, i) => i !== index));
    } else {
      setPriceRows(priceRows.filter((_, i) => i !== index));
    }
    if (editingRowIndex === index) {
      setEditingRowIndex(null);
      setEditingRowData({});
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const allRows = [...quantityRows, ...priceRows];
    const inventoryData = allRows.map(row => ({ ...formData, ...row, type: updateType }));
    navigate('/updateinventory', { state: { inventoryData } });
  };

  return (
    <>
      <div id='wrapper'>
        <div class="iq-sidebar sidebar-default ">

          <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
            <a class="header-logo m-auto p-auto">
              {/* <img src="./assets/images/page-img/green-mark-logo.png" class="img-fluid rounded-normal light-logo" alt="logo" /> */}
              <h5 class="logo-title light-logo text-success ">Green</h5>
              <h5 class="logo-title light-logo text-secondary">-Mark</h5>
            </a>
            <div class="iq-menu-bt-sidebar">
              <i class="las la-bars wrapper-menu"></i>
            </div>
          </div>

          <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <nav class="iq-sidebar-menu ">
              <ul id="iq-sidebar-toggle" class="iq-menu">
                <li class="">
                  <a href="/dashboard" class="svg-icon">
                    <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span class="ml-4">Dashboards</span>
                  </a>
                </li>
                <li class="">
                  <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <span class="ml-4">Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="category" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addcategory">
                        <i class="las la-minus"></i><span>Add Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listcategory">
                        <i class="las la-minus"></i><span>List of Category</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                    </svg>
                    <span class="ml-4">Sub-Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addsubcategory">
                        <i class="las la-minus"></i><span>Add Sub-Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listsubcategory">
                        <i class="las la-minus"></i><span>Sub-Category List</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span class="ml-4">Products</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="product" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addproduct">
                        <i class="las la-minus"></i><span>Add Product</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listproduct">
                        <i class="las la-minus"></i><span>List of Products</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="/listorder" class="svg-icon">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                    </svg>
                    <span class="ml-4">Orders</span>
                  </a>
                </li>
                <li class="active">
                  <a href="#updates" class="collapsed" data-toggle="collapse" aria-expanded="true">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M4 4h16v2H4z"></path>
                      <path d="M4 12h16v2H4z"></path>
                      <path d="M4 20h16v2H4z"></path>
                    </svg>
                    <span class="ml-4">Updates</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="updates" class="iq-submenu collapse show" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/pricebook">
                        <i class="las la-minus"></i><span>Price Book</span>
                      </a>
                    </li>
                    <li class="active">
                      <a href="/updateinventory">
                        <i class="las la-minus"></i><span>Update Inventory</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#people" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span class="ml-4">People</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="people" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/customer">
                        <i class="las la-minus"></i><span>Customer</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/user">
                        <i class="las la-minus"></i><span>Users</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/supplier">
                        <i class="las la-minus"></i><span>Suppliers</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <NavBar />
        <Header />
        <div className="content-page">
          <div className="container-fluid add-form-list">
            <div className="row mt-4">
              <div className="col-sm-12">
                <div class="card">
                <div className="card-header">
                    <div className="header-title d-flex justify-content-between align-items-center">
                      <h4 className="card-title text-primary">Update Inventory</h4>
                      <a className="btn btn-primary m-0 add-list" href="/inventorydetails">
                        <i className="las la-eye mr-3"></i>View Inventory
                      </a>
                    </div>
                  </div>
                  <div class="card-body">
                    <div className='row'>
                      <div className='col-xl-12'>
                        <div className="form-group">
                          <label htmlFor="updateType">Update Type</label>
                          <select
                            id="updateType"
                            name="updateType"
                            className="custom-select"
                            value={updateType}
                            onChange={handleUpdateTypeChange}
                          >
                            <option value="quantity">Quantity</option>
                            <option value="price">Price</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-xl-6'>
                        <div className="form-group">
                          <label htmlFor="date">Date</label>
                          <input
                            type="date"
                            id="date"
                            name="date"
                            className="form-control"
                            value={formData.date}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className='col-xl-6'>
                        <div className="form-group">
                          <label htmlFor="status">Status</label>
                          <input
                            type="text"
                            id="status"
                            name="status"
                            className="form-control"
                            value={formData.status}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className='col-xl-12'>
                        <div className="form-group">
                          <label htmlFor="referenceNumber">Reference Number</label>
                          <input
                            type="text"
                            id="referenceNumber"
                            name="referenceNumber"
                            className="form-control"
                            value={formData.referenceNumber}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className='col-xl-12'>
                        <div className="form-group">
                          <label htmlFor="createdBy">Created By</label>
                          <input
                            type="text"
                            id="createdBy"
                            name="createdBy"
                            className="form-control"
                            value={formData.createdBy}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {updateType === 'quantity' && (
                      <div className="table-responsive rounded mb-3 mt-4">
                        <table className="data-table table mb-0 tbl-server-info">
                          <thead className="bg-white text-uppercase">
                            <tr className="ligth ligth-data">
                              <th>Product</th>
                              <th>Current Quantity</th>
                              <th>Updated Quantity</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody className="ligth-body">
                            {quantityRows.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  {editingRowIndex === index
                                    ? <input type="text" name="product" className="form-control" value={editingRowData.product} onChange={handleTableInputChange} />
                                    : row.product}
                                </td>
                                <td>
                                  {editingRowIndex === index
                                    ? <input type="number" name="currentQuantity" className="form-control" value={editingRowData.currentQuantity} onChange={handleTableInputChange} />
                                    : row.currentQuantity}
                                </td>
                                <td>
                                  {editingRowIndex === index
                                    ? <input type="number" name="updatedQuantity" className="form-control" value={editingRowData.updatedQuantity} onChange={handleTableInputChange} />
                                    : row.updatedQuantity}
                                </td>
                                <td>
                                  {editingRowIndex === index ? (
                                    <>
                                      <button onClick={saveRow} className="btn btn-success">Save</button>
                                      <button onClick={() => deleteRow(index)} className="btn btn-danger ml-2">Delete</button>
                                    </>
                                  ) : (
                                    <>
                                      <button onClick={() => handleEditRow(index, row)} className="btn btn-secondary">Edit</button>
                                      <button onClick={() => deleteRow(index)} className="btn btn-danger ml-2">Delete</button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <button onClick={addRow} className="btn btn-secondary mt-3">Add Product</button>
                      </div>
                    )}

                    {updateType === 'price' && (
                      <div className="table-responsive rounded mb-3 mt-4">
                        <table className="data-table table mb-0 tbl-server-info">
                          <thead className="bg-white text-uppercase">
                            <tr className="ligth ligth-data">
                              <th>Product</th>
                              <th>Current Price</th>
                              <th>Updated Price</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody className="ligth-body">
                            {priceRows.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  {editingRowIndex === index
                                    ? <input type="text" className="form-control" name="product" value={editingRowData.product} onChange={handleTableInputChange} required />
                                    : row.product}
                                </td>
                                <td>
                                  {editingRowIndex === index
                                    ? <input type="number" className="form-control" name="currentPrice" value={editingRowData.currentPrice} onChange={handleTableInputChange} required />
                                    : row.currentPrice}
                                </td>
                                <td>
                                  {editingRowIndex === index
                                    ? <input type="number" className="form-control" name="updatedPrice" value={editingRowData.updatedPrice} onChange={handleTableInputChange} required />
                                    : row.updatedPrice}
                                </td>
                                <td>
                                  {editingRowIndex === index ? (
                                    <>
                                      <button onClick={saveRow} className="btn btn-success mr-2">Save</button>
                                      <button onClick={() => deleteRow(index)} className="btn btn-danger">Delete</button>
                                    </>
                                  ) : (
                                    <>
                                      <button onClick={() => handleEditRow(index, row)} className="btn btn-secondary mr-2">Edit</button>
                                      <button onClick={() => deleteRow(index)} className="btn btn-danger">Delete</button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <button onClick={addRow} className="btn btn-secondary mt-3">Add Product</button>
                      </div>
                    )}
                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default InventoryDetails;
