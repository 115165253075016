import React from 'react';

function ForgotPassword() {
    return (
        <div>
            <div class="container">
            <div class="row align-items-center justify-content-center height-self-center">
               <div class="col-lg-8">
                  <div class="card auth-card">
                  <div className="col-lg-6 text-center mx-auto mt-3">
                                    <img src="../assets/images/page-img/green-mark-logo.png" className="logo" alt="Logo" />
                                </div>
                     <div class="card-body p-0">
                        <div class="d-flex align-items-center auth-content">
                           <div class="col-lg-12 align-self-center">
                              <div class="p-5 text-center mx-auto">
                                 <h3 class="mb-2 text-primary">Reset Password</h3>
                                 <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
                                 <form>
                                    <div class="row">
                                       <div class="col-lg-8 text-center mx-auto">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="email" placeholder=" " required/>
                                             <label>Email</label>
                                          </div>
                                       </div>
                                    </div>
                                    <button type="submit" formAction='/successmail' class="btn btn-secondary">Reset</button>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
        </div>
    );
}

export default ForgotPassword;