import React, { useState } from 'react';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

function ListOrder() {

    return (
        <>
            <div id="wrapper">
                {/* <SideBar /> */}
                <div className="iq-sidebar sidebar-default">

                    <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
                        <a className="header-logo m-auto p-auto">
                            {/* <img src="./assets/images/page-img/green-mark-logo.png" className="img-fluid rounded-normal light-logo" alt="logo" /> */}
                            <h5 className="logo-title light-logo text-success ">Green</h5>
                            <h5 className="logo-title light-logo text-secondary">-Mark</h5>
                        </a>
                        <div className="iq-menu-bt-sidebar">
                            <i className="las la-bars wrapper-menu"></i>
                        </div>
                    </div>

                    <div className="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        <nav className="iq-sidebar-menu ">
                            <ul id="iq-sidebar-toggle" className="iq-menu">
                                <li className="">
                                    <a href="/dashboard" className="svg-icon">
                                        <svg className="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg>
                                        <span className="ml-4">Dashboards</span>
                                    </a>
                                </li>
                                <li className="">
                                    <a href="#category" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                        <span className="ml-4">Categories</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="category" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/addcategory">
                                                <i className="las la-minus"></i><span>Add Category</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/listcategory">
                                                <i className="las la-minus"></i><span>List of Category</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                                        </svg>
                                        <span class="ml-4">Sub-Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addsubcategory">
                                                <i class="las la-minus"></i><span>Add Sub-Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listsubcategory">
                                                <i class="las la-minus"></i><span>Sub-Category List</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="">
                                    <a href="#product" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="9" cy="21" r="1"></circle>
                                            <circle cx="20" cy="21" r="1"></circle>
                                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                        </svg>
                                        <span className="ml-4">Products</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="product" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/addproduct">
                                                <i className="las la-minus"></i><span>Add Product</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/listproduct">
                                                <i className="las la-minus"></i><span>List of Products</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="active">
                                    <a href="/listorder" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                                        </svg>
                                        <span class="ml-4">Orders</span>
                                    </a>
                                </li>

                                <li className="">
                                    <a href="#updates" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M4 4h16v2H4z"></path>
                                            <path d="M4 12h16v2H4z"></path>
                                            <path d="M4 20h16v2H4z"></path>
                                        </svg>
                                        <span className="ml-4">Updates</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="updates" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/pricebook">
                                                <i className="las la-minus"></i><span>Price Book</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/updateinventory">
                                                <i className="las la-minus"></i><span>Update Inventory</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="">
                                    <a href="#people" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="9" cy="7" r="4"></circle>
                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                        </svg>
                                        <span className="ml-4">People</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="people" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/customer">
                                                <i className="las la-minus"></i><span>Customers</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/user">
                                                <i className="las la-minus"></i><span>Users</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/supplier">
                                                <i className="las la-minus"></i><span>Suppliers</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <NavBar />
                <Header />
                <div className="content-page">
                    <div className="container-fluid add-form-list mt-4">
                        <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab-1" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active text-dark font-weight-bolder font-size-20 " id="pills-home-tab-fill" data-toggle="pill"
                                    href="#pills-home-fill" role="tab" aria-controls="pills-home" aria-selected="true">In Cart</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-dark font-weight-bolder font-size-20 " id="pills-profile-tab-fill" data-toggle="pill"
                                    href="#pills-profile-fill" role="tab" aria-controls="pills-profile" aria-selected="false">Ordered</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-dark font-weight-bolder font-size-20 " id="pills-contact-tab-fill" data-toggle="pill"
                                    href="#pills-contact-fill" role="tab" aria-controls="pills-contact" aria-selected="false">Delivered</a>
                            </li>
                        </ul>
                        <div class="card-body">
                            <div class="tab-content" id="pills-tabContent-1">
                                <div class="tab-pane fade active show" id="pills-home-fill" role="tabpanel" aria-labelledby="pills-home-tab-fill">
                                    <div className="col-lg-12">
                                        <div className="table-responsive rounded mb-3">
                                            <table className="data-table table mb-0 tbl-server-info">
                                                <thead className="bg-white text-uppercase">
                                                    <tr className="ligth ligth-data">
                                                        <th>Date</th>
                                                        <th>Customer ID</th>
                                                        <th>Customer Name</th>
                                                        <th>Customer Number</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="ligth-body">
                                                    <tr>
                                                        <td>09-09-2024</td>
                                                        <td><a className='font-weight-bold' href='/orderoverview'>FZ01</a></td>
                                                        <td>Venom</td>
                                                        <td>1231231234</td>
                                                        <td>₹ 149</td>
                                                    </tr>
                                                    <tr>
                                                        <td>08-09-2024</td>
                                                        <td><a className='font-weight-bold' href='/orderoverview'>FZ01</a></td>
                                                        <td>Stark</td>
                                                        <td>1231231234</td>
                                                        <td>₹ 56</td>
                                                    </tr>
                                                    <tr>
                                                        <td>08-09-2024</td>
                                                        <td><a className='font-weight-bold' href='/orderoverview'>FZ01</a></td>
                                                        <td>Jarvis</td>
                                                        <td>1231231234</td>
                                                        <td>₹ 75</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-profile-fill" role="tabpanel" aria-labelledby="pills-profile-tab-fill">
                                    <div className="col-lg-12">
                                        <div className="table-responsive rounded mb-3">
                                            <table className="data-table table mb-0 tbl-server-info">
                                                <thead className="bg-white text-uppercase">
                                                    <tr className="ligth ligth-data">
                                                        <th>Order Date</th>
                                                        <th>Order ID</th>
                                                        <th>Customer Name</th>
                                                        <th>Customer ID</th>
                                                        <th>Amount</th>
                                                        <th>Delivery Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="ligth-body">
                                                    <tr>
                                                        <td>09-09-2024</td>
                                                        <td><a className='font-weight-bold' href='/orderoverview'>Order 101</a></td>
                                                        <td>Ashwin</td>
                                                        <td>RE01</td>
                                                        <td>₹ 149</td>
                                                        <td>09-09-2024</td>
                                                    </tr>
                                                    <tr>
                                                        <td>09-09-2024</td>
                                                        <td><a className='font-weight-bold' href='/orderoverview'>Order 102</a></td>
                                                        <td>David</td>
                                                        <td>RE01</td>
                                                        <td>₹ 149</td>
                                                        <td>09-09-2024</td>
                                                    </tr>
                                                    <tr>
                                                        <td>09-09-2024</td>
                                                        <td><a className='font-weight-bold' href='/orderoverview'>Order 103</a></td>
                                                        <td>Priya</td>
                                                        <td>RE01</td>
                                                        <td>₹ 149</td>
                                                        <td>09-09-2024</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-contact-fill" role="tabpanel" aria-labelledby="pills-contact-tab-fill">
                                    <div className="col-lg-12">
                                        <div className="table-responsive rounded mb-3">
                                            <table className="data-table table mb-0 tbl-server-info">
                                                <thead className="bg-white text-uppercase">
                                                    <tr className="ligth ligth-data">
                                                        <th>Order Date</th>
                                                        <th>Order ID</th>
                                                        <th>Customer Name</th>
                                                        <th>Customer ID</th>
                                                        <th>Amount</th>
                                                        <th>Delivery Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="ligth-body">
                                                    <tr>
                                                        <td>09-09-2024</td>
                                                        <td><a className='font-weight-bold' href='/orderoverview'>Order 101</a></td>
                                                        <td>David</td>
                                                        <td>RE01</td>
                                                        <td>₹ 149</td>
                                                        <td>09-09-2024</td>
                                                    </tr>
                                                    <tr>
                                                        <td>09-09-2024</td>
                                                        <td><a className='font-weight-bold' href='/orderoverview'>Order 102</a></td>
                                                        <td>Priya</td>
                                                        <td>RE01</td>
                                                        <td>₹ 149</td>
                                                        <td>09-09-2024</td>
                                                    </tr>
                                                    <tr>
                                                        <td>09-09-2024</td>
                                                        <td><a className='font-weight-bold' href='/orderoverview'>Order 103</a></td>
                                                        <td>Ashwin</td>
                                                        <td>RE01</td>
                                                        <td>₹ 149</td>
                                                        <td>09-09-2024</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ListOrder;