
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SignIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (username === 'admin' && password === '1234') {
            toast.success('Login Successful!', {
                position: "top-right", autoClose: 1000, hideProgressBar: false, closeOnClick: true,
                pauseOnHover: true, draggable: true, progress: undefined, });
  
            setTimeout(() => {
                window.location.href = '/dashboard';
            }, 1000);
        } else {
            if (username !== 'admin') {
                toast.error('Incorrect Username', {
                    position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true,
                    pauseOnHover: true, draggable: true, progress: undefined, });
            } else if (password !== '1234') {
                toast.error('Incorrect Password', {
                    position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true,
                    pauseOnHover: true, draggable: true, progress: undefined, });
            } else {
                toast.error('Login Failed', {
                    position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true,
                    pauseOnHover: true, draggable: true, progress: undefined, });
            }
        }
    };

    return (
        <div>
            <div className="container">
                <div className="row align-items-center justify-content-center height-self-center">
                    <div className="col-lg-5">
                        <div className="card auth-card">
                            <div className="card-body p-0">
                                <div className="col-lg-6 text-center mx-auto mt-3">
                                    <img src="../assets/images/page-img/green-mark-logo.png" className="logo" alt="Logo" />
                                </div>
                                <div className="d-flex align-items-center auth-content">
                                    <div className="col-lg-12 align-self-center">
                                        <div className="p-3">
                                            <div className='text-center mx-auto'>
                                                <h2 className="my-4 text-primary">Sign In</h2>
                                            </div>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="floating-label form-group">
                                                            <input className="floating-input form-control" type="text" placeholder="admin"
                                                                value={username} onChange={(e) => setUsername(e.target.value)} required />
                                                            <label>Username</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="floating-label form-group position-relative">
                                                            <input className="floating-input form-control pr-4" type={showPassword ? 'text' : 'password'} placeholder="1234"
                                                                value={password} onChange={(e) => setPassword(e.target.value)} required />
                                                            <span className="show-pass eye position-absolute" onClick={togglePasswordVisibility}>
                                                                {showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                                            </span>
                                                            <label>Password</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                            <label className="custom-control-label control-label-1" htmlFor="customCheck1">Remember Me</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <a href="/forgotpassword" className="text-secondary float-right">Forgot Password?</a>
                                                    </div>
                                                </div>
                                                <div className='text-center mx-auto my-2'>
                                                    <button type="submit" className="btn btn-block btn-primary">Sign In</button>
                                                </div>
                                                <p className="mt-3">
                                                    Create an Account? <a href="/signup" className="text-secondary">Sign Up</a>
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default SignIn;
