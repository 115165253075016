import React from 'react';
import Header from '../../components/layouts/Header';
import NavBar from '../../components/layouts/NavBar';
import Footer from '../../components/layouts/Footer';

function AddOrder() {
    return (
        <>
            <div id="wrapper">
                {/* <SideBar /> */}
                <div className="iq-sidebar sidebar-default">

                    <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
                        <a className="header-logo m-auto p-auto">
                            {/* <img src="./assets/images/page-img/green-mark-logo.png" className="img-fluid rounded-normal light-logo" alt="logo" /> */}
                            <h5 className="logo-title light-logo text-success ">Green</h5>
                            <h5 className="logo-title light-logo text-secondary">-Mark</h5>
                        </a>
                        <div className="iq-menu-bt-sidebar">
                            <i className="las la-bars wrapper-menu"></i>
                        </div>
                    </div>

                    <div className="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        <nav className="iq-sidebar-menu ">
                            <ul id="iq-sidebar-toggle" className="iq-menu">
                                <li className="">
                                    <a href="/dashboard" className="svg-icon">
                                        <svg className="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg>
                                        <span className="ml-4">Dashboards</span>
                                    </a>
                                </li>
                                <li className="">
                                    <a href="#category" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                        <span className="ml-4">Categories</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="category" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/addcategory">
                                                <i className="las la-minus"></i><span>Add Category</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/listcategory">
                                                <i className="las la-minus"></i><span>List of Category</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                                        </svg>
                                        <span class="ml-4">Sub-Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addsubcategory">
                                                <i class="las la-minus"></i><span>Add Sub-Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listsubcategory">
                                                <i class="las la-minus"></i><span>Sub-Category List</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="">
                                    <a href="#product" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="9" cy="21" r="1"></circle>
                                            <circle cx="20" cy="21" r="1"></circle>
                                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                        </svg>
                                        <span className="ml-4">Products</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="product" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/addproduct">
                                                <i className="las la-minus"></i><span>Add Product</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/listproduct">
                                                <i className="las la-minus"></i><span>List of Products</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="active">
                                    <a href="/listorder" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                                        </svg>
                                        <span class="ml-4">Orders</span>
                                    </a>
                                </li>
                                <li className="">
                                    <a href="#updates" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M4 4h16v2H4z"></path>
                                            <path d="M4 12h16v2H4z"></path>
                                            <path d="M4 20h16v2H4z"></path>
                                        </svg>
                                        <span className="ml-4">Updates</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="updates" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/pricebook">
                                                <i className="las la-minus"></i><span>Price Book</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/updateinventory">
                                                <i className="las la-minus"></i><span>Update Inventory</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="">
                                    <a href="#people" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="9" cy="7" r="4"></circle>
                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                        </svg>
                                        <span className="ml-4">People</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="people" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/customer">
                                                <i className="las la-minus"></i><span>Customers</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/user">
                                                <i className="las la-minus"></i><span>Users</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/supplier">
                                                <i className="las la-minus"></i><span>Suppliers</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <NavBar />
                <Header />
                <div className="content-page">
                    <div className="container-fluid add-form-list">
                        <div className="row">
                            <div className="col-lg-12 my-4">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div>
                                        <h4 className="m-0 p-0 text-primary my-2">Order Overview</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl col-lg-6 my-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='d-flex justify-content-between'>
                                            <h6 className="text-orange mb-4">Shipping Details</h6><i className='text-dark fas fa-truck'></i>
                                        </div>
                                        <h6 className="mb-3">Pauline Hylton</h6>
                                        <p className="m-0 text-muted">1235 Crossing Meadows Dr, Onalaska,</p>
                                        <p className="text-muted">West Virginia, USA</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl col-lg-6 my-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='d-flex justify-content-between'>
                                            <h6 className="text-orange mb-4">Billing Details</h6><i className='text-dark fas fa-credit-card'></i>
                                        </div>
                                        <h6 className="mb-3">Pauline Hylton</h6>
                                        <p className="m-0 text-muted">1235 Crossing Meadows Dr, Onalaska,</p>
                                        <p className="text-muted">West Virginia, USA</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl col-lg-6 my-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='d-flex justify-content-between'>
                                            <h6 className="text-orange mb-4">Payment Details</h6><i className='text-dark fas fa-dollar'></i>
                                        </div>
                                        <h6 className="mb-3">ID : #TSD456DF41SD5</h6>
                                        <p className="m-0 text-muted">Payment Method : <b>Credit Card</b></p>
                                        <p className="text-muted">Card No : <b>xxxx xxxx xxxx 1501</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl col-lg-6 my-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='d-flex justify-content-between'>
                                            <h6 className="text-orange mb-4">Customer Info</h6><i className='text-dark fas fa-user'></i>
                                        </div>
                                        <h6 className="mb-3">Pauline Hylton</h6>
                                        <p className="m-0 text-muted">pauline@tailwick.com</p>
                                        <p className="text-muted"><b>+91 638 248 6876</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <h6 className="mb-1 underline">#TWT5015100366</h6>
                                        <p className="text-uppercase text-dark"><b>Order ID</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <h6 className="mb-1">02 Nov, 2023</h6>
                                        <p className="text-uppercase text-dark"><b>Order Date</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <h6 className="mb-1">09 Nov, 2023</h6>
                                        <p className="text-uppercase text-dark"><b>Order Date</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <h6 className="mb-1">$843.49</h6>
                                        <p className="text-uppercase text-dark"><b>Order Amount</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <h6 className="mb-1">Shipping</h6>
                                        <p className="text-uppercase text-dark"><b>Order Status</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className='card-header d-flex align-items-center justify-content-between'>
                                <h5 className="text-primary">Order Summary</h5>
                                <h6 className="text-danger">Cancel Order</h6>
                            </div>
                            <div className="card-body">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='d-flex'>
                                        <img src="../assets/images/product-img/cream-milk.webp"
                                            alt='product' className='img-fluid rounded avatar-50 mr-3' />
                                        <span>
                                            <h6>Cream Milk</h6>
                                            <p>₹ 79.39 x 02</p>
                                        </span>
                                    </div>
                                    <h6>₹ 158.78</h6>
                                </div>
                                <hr />
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='d-flex'>
                                        <img src="../assets/images/product-img/spinach.webp"
                                            alt='product' className='img-fluid rounded avatar-50 mr-3' />
                                        <span>
                                            <h6>Spinach</h6>
                                            <p>₹ 49.69 x 01</p>
                                        </span>
                                    </div>
                                    <h6>₹ 49.69</h6>
                                </div>
                                <hr />
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='d-flex'>
                                        <img src="../assets/images/product-img/strawberry.webp"
                                            alt='product' className='img-fluid rounded avatar-50 mr-3' />
                                        <span>
                                            <h6>Strawberry</h6>
                                            <p>₹ 149.99 x 02</p>
                                        </span>
                                    </div>
                                    <h6>₹ 299.98</h6>
                                </div>
                                <hr />
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6>Sub-Total <span className='text-dark'>(Rupees)</span></h6>
                                    <h6>₹ 508.45</h6>
                                </div>
                                <hr />
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6>Estimated Tax <span className='text-dark'>(18%)</span></h6>
                                    <h6>₹ 67.79</h6>
                                </div>
                                <hr />
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6>Item Discounts <span className='text-dark'>(12%)</span></h6>
                                    <h6>₹ -102.86</h6>
                                </div>
                                <hr />
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6>Shipping Charge</h6>
                                    <h6>₹ 0</h6>
                                </div>
                                <hr />
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6>Total Amount <span className='text-dark'>(Rupees)</span></h6>
                                    <h6>₹ 473.38</h6>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className='card-header d-flex align-items-center justify-content-between'>
                                <h5 className="text-primary">Order Status</h5>
                                <a href='/invoice' className="btn btn-orange"><i className='fas fa-eye mr-3'></i>Invoice</a>
                            </div>
                            <div className="card-body">
                                <ol className="pf-v5-c-progress-stepper pf-m-vertical">
                                    <li className="pf-v5-c-progress-stepper__step pf-m-success" role="listitem" aria-label="completed step,">
                                        <div className="pf-v5-c-progress-stepper__step-connector">
                                            <span className="pf-v5-c-progress-stepper__step-icon">
                                                <i className="fas fa-check-circle" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div className="pf-v5-c-progress-stepper__step-main">
                                            <div className="pf-v5-c-progress-stepper__step-title">Order Placed</div>
                                            <div className="pf-v5-c-progress-stepper__step-description">
                                                Your order has been successfully submitted.
                                            </div>
                                            <h6 className="step-date">02 Nov, 2023</h6>
                                        </div>
                                    </li>
                                    <li className="pf-v5-c-progress-stepper__step pf-m-success" role="listitem" aria-label="completed step,">
                                        <div className="pf-v5-c-progress-stepper__step-connector">
                                            <span className="pf-v5-c-progress-stepper__step-icon">
                                                <i className="fas fa-check-circle" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div className="pf-v5-c-progress-stepper__step-main">
                                            <div className="pf-v5-c-progress-stepper__step-title">Order Processing</div>
                                            <div className="pf-v5-c-progress-stepper__step-description">
                                                Once the order is received, it goes through the processing stage. During this time, the items are gathered, and the order is prepared for shipment.
                                            </div>
                                            <h6 className="step-date">02 Nov, 2023</h6>
                                        </div>
                                    </li>
                                    <li className="pf-v5-c-progress-stepper__step pf-m-current pf-m-info" role="listitem" aria-label="current step, in process step,">
                                        <div className="pf-v5-c-progress-stepper__step-connector">
                                            <span className="pf-v5-c-progress-stepper__step-icon">
                                                <i className="pf-v5-pficon pf-v5-pficon-resources-full" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div className="pf-v5-c-progress-stepper__step-main">
                                            <div className="pf-v5-c-progress-stepper__step-title">Shipped Order</div>
                                            <div className="pf-v5-c-progress-stepper__step-description">
                                                The order is shipped out to the customer's designated delivery address.
                                            </div>
                                            <h6 className="step-date">04 Nov, 2023</h6>
                                        </div>
                                    </li>
                                    <li className="pf-v5-c-progress-stepper__step pf-m-pending" role="listitem" aria-label="pending step,">
                                        <div className="pf-v5-c-progress-stepper__step-connector">
                                            <span className="pf-v5-c-progress-stepper__step-icon"></span>
                                        </div>
                                        <div className="pf-v5-c-progress-stepper__step-main">
                                            <div className="pf-v5-c-progress-stepper__step-title">Out for Delivery</div>
                                            <div className="pf-v5-c-progress-stepper__step-description">
                                                This status indicates that the order is currently out for delivery by the shipping or courier company.
                                            </div>
                                            <h6 className="step-date">06 Nov, 2023</h6>
                                        </div>
                                    </li>
                                    <li className="pf-v5-c-progress-stepper__step pf-m-pending" role="listitem" aria-label="pending step,">
                                        <div className="pf-v5-c-progress-stepper__step-connector">
                                            <span className="pf-v5-c-progress-stepper__step-icon"></span>
                                        </div>
                                        <div className="pf-v5-c-progress-stepper__step-main">
                                            <div className="pf-v5-c-progress-stepper__step-title">Delivered</div>
                                            <div className="pf-v5-c-progress-stepper__step-description">
                                                Finally, when the order successfully reaches the customer's address and is handed over, the status changes to "Delivered."
                                            </div>
                                            <h6 className="step-date">09 Nov, 2023</h6>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default AddOrder;