import React, { useState } from 'react';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

function ListSale() {
    const [listSale, setListSale] = useState([
        { id: 1, date: '12-08-2024', customer: 'John Doe', total: 99.00, paid: 99.00, status: 'Completed', biller: 'Store A', tax: 8.00 },
        { id: 2, date: '16-08-2024', customer: 'Jane Smith', total: 49.00, paid: 49.00, status: 'Completed', biller: 'Store B', tax: 4.00 },
        { id: 3, date: '22-08-2024', customer: 'Alice Johnson', total: 89.00, paid: 89.00, status: 'Pending', biller: 'Store C', tax: 7.00 },
        { id: 4, date: '28-08-2024', customer: 'Bob Brown', total: 19.00, paid: 19.00, status: 'Completed', biller: 'Store D', tax: 1.50 },
        { id: 5, date: '15-07-2024', customer: 'Carol White', total: 29.00, paid: 29.00, status: 'Refunded', biller: 'Store E', tax: 2.30 },
        { id: 6, date: '21-07-2024', customer: 'David Black', total: 64.00, paid: 64.00, status: 'Completed', biller: 'Store F', tax: 5.12 },
        { id: 7, date: '12-07-2024', customer: 'Eva Green', total: 19.00, paid: 19.00, status: 'Completed', biller: 'Store G', tax: 1.50 },
        { id: 8, date: '09-07-2024', customer: 'Frank Blue', total: 49.00, paid: 49.00, status: 'Completed', biller: 'Store H', tax: 4.00 },
    ]);

    const [editModalVisible, setEditModalVisible] = useState(false);
    const [saleToEdit, setSaleToEdit] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [saleToDelete, setSaleToDelete] = useState(null);

    const handleEdit = (id) => {
        const sale = listSale.find(sale => sale.id === id);
        setSaleToEdit(sale);
        setEditModalVisible(true);
    };

    const handleUpdatesale = (updatedsale) => {
        const updatedlistSale = listSale.map(sale =>
            sale.id === updatedsale.id ? updatedsale : sale
        );
        setListSale(updatedlistSale);
        setEditModalVisible(false);
    };

    const handleDelete = (id) => {
        setSaleToDelete(id);
        setDeleteModalVisible(true);
    };

    const handleConfirmDelete = () => {
        const updatedlistSale = listSale.filter(sale => sale.id !== saleToDelete);
        setListSale(updatedlistSale);
        setDeleteModalVisible(false);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalVisible(false);
    };

    const handleCloseEditModal = () => {
        setEditModalVisible(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSaleToEdit(prevsale => ({ ...prevsale, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (saleToEdit) {
            handleUpdatesale(saleToEdit);
        }
    };

    return (
        <>
            <div id='wrapper'>
                {/* <SideBar/> */}
                <div class="iq-sidebar sidebar-default ">

                    <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
                        <a class="header-logo m-auto p-auto">
                            {/* <img src="./assets/images/page-img/green-mark-logo.png" class="img-fluid rounded-normal light-logo" alt="logo" /> */}
                            <h5 class="logo-title light-logo text-success ">Green</h5>
                            <h5 class="logo-title light-logo text-secondary">-Mark</h5>
                        </a>
                        <div class="iq-menu-bt-sidebar">
                            <i class="las la-bars wrapper-menu"></i>
                        </div>
                    </div>

                    <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        <nav class="iq-sidebar-menu ">
                            <ul id="iq-sidebar-toggle" class="iq-menu">
                                <li class="">
                                    <a href="/dashboard" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg>
                                        <span class="ml-4">Dashboards</span>
                                    </a>
                                </li>
                                <li class="">
                                    <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                        <span class="ml-4">Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="category" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addcategory">
                                                <i class="las la-minus"></i><span>Add Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listcategory">
                                                <i class="las la-minus"></i><span>List of Category</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                                        </svg>
                                        <span class="ml-4">Sub-Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addsubcategory">
                                                <i class="las la-minus"></i><span>Add Sub-Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listsubcategory">
                                                <i class="las la-minus"></i><span>Sub-Category List</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="9" cy="21" r="1"></circle>
                                            <circle cx="20" cy="21" r="1"></circle>
                                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                        </svg>
                                        <span class="ml-4">Products</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="product" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addproduct">
                                                <i class="las la-minus"></i><span>Add Product</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listproduct">
                                                <i class="las la-minus"></i><span>List of Products</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="/listorder" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                                        </svg>
                                        <span class="ml-4">Orders</span>
                                    </a>
                                </li>
                                <li class="">
                                    <a href="#updates" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M4 4h16v2H4z"></path>
                                            <path d="M4 12h16v2H4z"></path>
                                            <path d="M4 20h16v2H4z"></path>
                                        </svg>
                                        <span class="ml-4">Updates</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="updates" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/pricebook">
                                                <i class="las la-minus"></i><span>Price Book</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/updateinventory">
                                                <i class="las la-minus"></i><span>Update Inventory</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="">
                                    <a href="#people" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="9" cy="7" r="4"></circle>
                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                        </svg>
                                        <span class="ml-4">People</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="people" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/customer">
                                                <i class="las la-minus"></i><span>Customers</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/user">
                                                <i class="las la-minus"></i><span>Users</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/supplier">
                                                <i class="las la-minus"></i><span>Suppliers</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <NavBar />
                <Header />
                <div className="content-page">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 my-4">
                                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                    <div>
                                        <h4 className="m-0 p-0 text-primary">Sale List</h4>
                                    </div>
                                    <a href='/addsale' className="btn btn-primary m-0 add-list"><i className="las la-plus mr-3"></i>Add Sale</a>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="table-responsive rounded mb-3">
                                    <table className="data-table table mb-0 tbl-server-info">
                                        <thead className="bg-white text-uppercase">
                                            <tr className="ligth ligth-data">
                                                <th>Date</th>
                                                <th>Customer</th>
                                                <th>Total</th>
                                                <th>Paid</th>
                                                <th>Status</th>
                                                <th>Biller</th>
                                                <th>Tax</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="ligth-body">
                                            {listSale.map(sale => (
                                                <tr key={sale.id}>
                                                    <td>{sale.date}</td>
                                                    <td>{sale.customer}</td>
                                                    <td>₹{sale.total}</td>
                                                    <td>₹{sale.paid}</td>
                                                    <td><div className="badge badge-success">{sale.status}</div></td>
                                                    <td>{sale.biller}</td>
                                                    <td>₹{sale.tax}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center list-action">
                                                            <a className="badge bg-warning mr-2" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => handleEdit(sale.id)}>
                                                                <i className="ri-pencil-line mr-0"></i>
                                                            </a>
                                                            <a className="badge bg-danger mr-2" data-toggle="tooltip" data-placement="top" title="Delete" onClick={() => handleDelete(sale.id)}>
                                                                <i className="ri-delete-bin-line mr-0"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {editModalVisible &&
                        <div className="modal" style={{ display: 'block' }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-primary">Edit Sale</h5>
                                        <button type="button" className="close" onClick={handleCloseEditModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="date">Date</label>
                                                <input type="date" id="date" name="date" className="form-control"
                                                    value={saleToEdit?.date || ''} onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="customer">Customer</label>
                                                <input type="text" id="customer" name="customer" className="form-control"
                                                    value={saleToEdit?.customer || ''} onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="paid">Paid</label>
                                                <input type="text" id="paid" name="paid" className="form-control"
                                                    value={saleToEdit?.paid || ''} onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="biller">Biller</label>
                                                <input type="text" id="biller" name="biller" className="form-control"
                                                    value={saleToEdit?.biller || ''} onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="tax">Tax</label>
                                                <input type="text" id="tax" name="tax" className="form-control"
                                                    value={saleToEdit?.tax || ''} onChange={handleChange} />
                                            </div>
                                            <button type="submit" className="btn btn-primary">Save changes</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {deleteModalVisible &&
                        <div className="modal" style={{ display: 'block' }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Confirm Delete</h5>
                                        <button type="button" className="close" onClick={handleCloseDeleteModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you want to delete this sale?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
                                        <button type="button" className="btn btn-secondary" onClick={handleCloseDeleteModal}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <Footer />
            </div>
        </>
    );
}

export default ListSale;
