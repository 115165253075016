import React from 'react';

function SuccessMail() {
    return (
        <div>
            <div class="container">
            <div class="row align-items-center justify-content-center height-self-center">
               <div class="col-lg-8">
                  <div class="card auth-card">
                  <div className="col-lg-6 text-center mx-auto mt-3">
                                    <img src="../assets/images/page-img/green-mark-logo.png" className="logo" alt="Logo" />
                                </div>
                     <div class="card-body p-0">
                        <div class="d-flex align-items-center auth-content">
                           <div class="col-lg-12 align-self-center">
                              <div class="p-3 text-center mx-auto">
                                 <h2 class="mt-3">Success !</h2>
                                 <p class="mt-2">A email has been send to your registered mail. Please check for an
                                    email from company and click
                                    on the provided link to reset your password.</p>
                                 <div class="d-inline-block w-100">
                                    <a href="/" class="btn btn-secondary mt-3">Back to SignIn Page</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
        </div>
    );
}

export default SuccessMail;