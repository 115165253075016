import React from 'react'

function PreLoader() {
  return (
    <>
      <div className="preloader">
        <div id="loading-center">
        {/* <img src="../assets/images/loader-drops.gif" alt="Loading..." /> */}
        {/* <img src="../assets/images/Lightning.gif" alt="Loading..." /> */}
        </div>
      </div>
    </>
  )
}

export default PreLoader