
const Signin = '/';
const SignUp = '/signup';
const ForgotPassword = "/forgotpassword";
const Profile = "/profile";
const SuccessMail = "/successmail";
const DashBoard = "/dashboard";
const AddCategory = "/addcategory";
const ListCategory = "/listcategory";
const AddSubCategory = "/addsubcategory"
const ListSubCategory = "/listsubcategory"
const AddProduct = "/addproduct";
const ListProduct = "/listproduct";
const User = "/user";
const AddUser = "/adduser";
const Customer = "/customer";
const AddCustomer = "/addcustomer";
const Supplier = "/supplier";
const AddSupplier = "/addsupplier";
const Invoice = "/invoice";
const AddSale = "/addsale";
const ListSale = "/listsale";
const PriceBook = "/pricebook";
const AddPriceBook = "/addpricebook";
const UpdateInventory = "/inventorydetails";
const InventoryDetails = "/updateinventory";
const OrderOverview = "/orderoverview";
const ListOrder = "/listorder"

export const BaseRoutes = {
    Signin,
    SignUp,
    ForgotPassword,
    Profile,
    SuccessMail,
    DashBoard,
    AddCategory,
    ListCategory,
    AddSubCategory,
    ListSubCategory,
    AddProduct,
    ListProduct,
    User,
    AddUser,
    Customer,
    AddCustomer,
    Supplier,
    AddSupplier,
    Invoice,
    AddSale,
    ListSale,
    PriceBook,
    AddPriceBook,
    UpdateInventory,
    InventoryDetails,
    OrderOverview,
    ListOrder,
}