import React, { useState, useEffect } from 'react';
import NavBar from "../../components/layouts/NavBar";
import Footer from "../../components/layouts/Footer";
import Header from "../../components/layouts/Header";

function AddSubCategory() {
    const [categories, setCategories] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');
    const [subCategoryCode, setSubCategoryCode] = useState('');
    const [imageFile, setImageFile] = useState(null); // Store image file here
    const [preview, setPreview] = useState('');
    const [imageName, setImageName] = useState('');
    const [alert, setAlert] = useState({ type: '', message: '', visible: false });

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('http://206.189.134.171:8000/categories/', {
                    method: 'GET',
                    headers: {
                        'accept': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setCategories(data);
                } else {
                    showAlert('danger', 'Failed to fetch categories');
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
                showAlert('danger', 'An error occurred while fetching categories');
            }
        };

        fetchCategories();
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPreview(URL.createObjectURL(file));
            const nameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
            setImageName(nameWithoutExtension);
            setImageFile(file); // Store the image file for base64 conversion
        }
    };

    const convertImageToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]); // Get base64 string
            reader.onerror = (error) => reject(error);
        });
    };

    const showAlert = (type, message) => {
        setAlert({ type, message, visible: true });
        setTimeout(() => setAlert({ ...alert, visible: false }), 3000);
    };

    const handleReset = () => {
        setCategoryName('');
        setSubCategoryName('');
        setSubCategoryCode('');
        setPreview('');
        setImageName('');
        setImageFile(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let base64Image = '';
        if (imageFile) {
            base64Image = await convertImageToBase64(imageFile);
        }

        const data = {
            category_name: categoryName,
            sub_category_name: subCategoryName,
            sub_category_id: subCategoryCode,
            sub_category_image: base64Image,
        };

        try {
            const response = await fetch('http://206.189.134.171:8000/subcategories/', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                showAlert('success', 'Sub-Category added successfully!');
                handleReset();
            } else {
                showAlert('danger', 'Failed to add Sub-Category');
            }
        } catch (error) {
            console.error('Error:', error);
            showAlert('danger', 'An error occurred');
        }
    };

    return (
        <>
            <div id='wrapper'>
                <div class="iq-sidebar sidebar-default ">

                    <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
                        <a class="header-logo m-auto p-auto">
                            {/* <img src="./assets/images/page-img/green-mark-logo.png" class="img-fluid rounded-normal light-logo" alt="logo" /> */}
                            <h5 class="logo-title light-logo text-success ">Green</h5>
                            <h5 class="logo-title light-logo text-secondary">-Mark</h5>
                        </a>
                        <div class="iq-menu-bt-sidebar">
                            <i class="las la-bars wrapper-menu"></i>
                        </div>
                    </div>

                    <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        <nav class="iq-sidebar-menu ">
                            <ul id="iq-sidebar-toggle" class="iq-menu">
                                <li class="">
                                    <a href="/dashboard" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg>
                                        <span class="ml-4">Dashboards</span>
                                    </a>
                                </li>
                                <li class="">
                                    <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                        <span class="ml-4">Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="category" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addcategory">
                                                <i class="las la-minus"></i><span>Add Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listcategory">
                                                <i class="las la-minus"></i><span>List of Category</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="active">
                                    <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="true">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                                        </svg>
                                        <span class="ml-4">Sub-Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="subcategory" class="iq-submenu collapse show" data-parent="#iq-sidebar-toggle">
                                        <li class="active">
                                            <a href="/addsubcategory">
                                                <i class="las la-minus"></i><span>Add Sub-Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listsubcategory">
                                                <i class="las la-minus"></i><span>Sub-Category List</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="9" cy="21" r="1"></circle>
                                            <circle cx="20" cy="21" r="1"></circle>
                                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                        </svg>
                                        <span class="ml-4">Products</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="product" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addproduct">
                                                <i class="las la-minus"></i><span>Add Product</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listproduct">
                                                <i class="las la-minus"></i><span>List of Products</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="/listorder" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                                        </svg>
                                        <span class="ml-4">Orders</span>
                                    </a>
                                </li>
                                <li class="">
                                    <a href="#updates" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M4 4h16v2H4z"></path>
                                            <path d="M4 12h16v2H4z"></path>
                                            <path d="M4 20h16v2H4z"></path>
                                        </svg>
                                        <span class="ml-4">Updates</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="updates" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/pricebook">
                                                <i class="las la-minus"></i><span>Price Book</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/updateinventory">
                                                <i class="las la-minus"></i><span>Update Inventory</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#people" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="9" cy="7" r="4"></circle>
                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                        </svg>
                                        <span class="ml-4">People</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="people" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/customer">
                                                <i class="las la-minus"></i><span>Customers</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/user">
                                                <i class="las la-minus"></i><span>Users</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/supplier">
                                                <i class="las la-minus"></i><span>Suppliers</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <NavBar />
                <Header />
                <div className="content-page">
                    <div className="container-fluid add-form-list">
                        <div className="row mt-4">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="header-title d-flex flex-wrap justify-content-between align-items-center">
                                            <h4 className="card-title text-primary my-2">Add Sub-Category</h4>
                                            <a className="btn btn-primary m-0 add-list my-2" href="/listsubcategory">
                                                <i className="las la-eye mr-3"></i>View Sub-Categories
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className='col-md-8'>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Category Name *</label>
                                                            <select className="custom-select" id="category_name" value={categoryName}
                                                                onChange={(e) => setCategoryName(e.target.value)} required >
                                                                <option value="" disabled>Select Category</option>
                                                                {categories.map((category) => (
                                                                    <option key={category.id} value={category.category_name}>
                                                                        {category.category_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <div className="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Sub-Category Image *</label>
                                                            <input type="file" className="form-control image-file" accept="image/*"
                                                                onChange={handleImageChange} name='sub_category_image' required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Sub-Category Name *</label>
                                                            <input type="text" className="form-control" placeholder="Enter Sub-Category Name" value={subCategoryName}
                                                                onChange={(e) => setSubCategoryName(e.target.value)} name='sub_category_name' required />
                                                            <div className="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Sub-Category Code *</label>
                                                            <input type="text" className="form-control" placeholder="Enter Code" value={subCategoryCode}
                                                                onChange={(e) => setSubCategoryCode(e.target.value)} name='sub_category_id' required />
                                                            <div className="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    {preview && (
                                                        <div className="image-preview carousel-inner m-0 text-center">
                                                            <img src={preview} alt="Sub-Category Preview" className="img-fluid subcat-img-prev my-3" />
                                                            <div className="carousel-caption">
                                                                <h5 className="text-white text-outline">{imageName.toUpperCase()}</h5>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary mr-2">Add Sub-Category</button>
                                            <button type="reset" className="btn btn-danger" onClick={handleReset}>Reset</button>
                                        </form>
                                    </div>
                                    {alert.visible && (
                                        <div className={`alert alert-${alert.type} border-dark`} role="alert">
                                            <div className="iq-alert-icon text-danger">
                                                <i className={`ri-${alert.type === 'success' ? 'check-line' : 'information-line'}`}></i>
                                            </div>
                                            <div className="iq-alert-text text-orange">{alert.message}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default AddSubCategory;
