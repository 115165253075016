import React, { useState } from 'react';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

function PriceBook() {

  const [priceBookData, setPriceBookData] = useState([
    {
      name: 'PriceBook 1',
      currency: 'USD',
      details: 'mark_down [ 10% ]',
      roundOffPreference: 'Nearest 0.49'
    },
    {
      name: 'PriceBook 2',
      currency: 'EUR',
      details: 'mark_up [ 5% ]',
      roundOffPreference: 'Nearest 0.10'
    }
  ]);

  const handleCheckboxChange = (index) => {
    const updatedPriceBookData = priceBookData.map((row, i) => ({
      ...row,
      isActive: i === index ? !row.isActive : false
    }));
    setPriceBookData(updatedPriceBookData);
  };


  return (
    <>
      <div id='wrapper'>
        {/* <SideBar/> */}
        <div class="iq-sidebar sidebar-default ">

          <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
            <a class="header-logo m-auto p-auto">
              {/* <img src="./assets/images/page-img/green-mark-logo.png" class="img-fluid rounded-normal light-logo" alt="logo" /> */}
              <h5 class="logo-title light-logo text-success ">Green</h5>
              <h5 class="logo-title light-logo text-secondary">-Mark</h5>
            </a>
            <div class="iq-menu-bt-sidebar">
              <i class="las la-bars wrapper-menu"></i>
            </div>
          </div>

          <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <nav class="iq-sidebar-menu ">
              <ul id="iq-sidebar-toggle" class="iq-menu">
                <li class="">
                  <a href="/dashboard" class="svg-icon">
                    <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span class="ml-4">Dashboards</span>
                  </a>
                </li>
                <li class="">
                  <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <span class="ml-4">Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="category" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addcategory">
                        <i class="las la-minus"></i><span>Add Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listcategory">
                        <i class="las la-minus"></i><span>List of Category</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                    </svg>
                    <span class="ml-4">Sub-Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addsubcategory">
                        <i class="las la-minus"></i><span>Add Sub-Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listsubcategory">
                        <i class="las la-minus"></i><span>Sub-Category List</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span class="ml-4">Products</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="product" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addproduct">
                        <i class="las la-minus"></i><span>Add Product</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listproduct">
                        <i class="las la-minus"></i><span>List of Products</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="/listorder" class="svg-icon">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                    </svg>
                    <span class="ml-4">Orders</span>
                  </a>
                </li>
                <li class="active">
                  <a href="#updates" class="collapsed" data-toggle="collapse" aria-expanded="true">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M4 4h16v2H4z"></path>
                      <path d="M4 12h16v2H4z"></path>
                      <path d="M4 20h16v2H4z"></path>
                    </svg>
                    <span class="ml-4">Updates</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="updates" class="iq-submenu collapse show" data-parent="#iq-sidebar-toggle">
                    <li class="active">
                      <a href="/pricebook">
                        <i class="las la-minus"></i><span>Price Book</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/updateinventory">
                        <i class="las la-minus"></i><span>Update Inventory</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#people" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span class="ml-4">People</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="people" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/customer">
                        <i class="las la-minus"></i><span>Customer</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/user">
                        <i class="las la-minus"></i><span>Users</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/supplier">
                        <i class="las la-minus"></i><span>Suppliers</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <NavBar />
        <Header />
        <div className="content-page">
          <div className="container-fluid add-form-list">
            <div className="row my-4">
              <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <h4 className="m-0 p-0 text-primary">Price Book</h4>
                  <a href='/addpricebook' className="btn btn-primary m-0 add-list"><i className="las la-plus mr-3"></i> Add PriceBook</a>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="table-responsive rounded mb-3">
                <table className="data-table table mb-0 tbl-server-info">
                  <thead className="bg-white text-uppercase">
                    <tr className='ligth ligth-data mx-auto text-center'>
                      <th>Name</th>
                      <th>Currency</th>
                      <th>Details</th>
                      <th>Round Off Preference</th>
                      <th>Active</th>
                    </tr>
                  </thead>
                  <tbody className="ligth-body mx-auto text-center">
                    {priceBookData.length > 0 ? (
                      priceBookData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.name}</td>
                          <td>{row.currency}</td>
                          <td>{row.details}</td>
                          <td>{row.roundOffPreference}</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={row.isActive}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default PriceBook;