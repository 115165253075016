import React, { useState, useEffect } from 'react';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

function Customer() {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [customerToEdit, setCustomerToEdit] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [alert, setAlert] = useState({ type: '', message: '', visible: false });
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const showAlert = (type, message) => {
    setAlert({ type, message, visible: true });
    setTimeout(() => setAlert({ ...alert, visible: false }), 3000);
  };

  useEffect(() => {
    fetch('http://206.189.134.171:8000/customers/', {
      headers: {
        'accept': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setCustomers(data);
        setFilteredCustomers(data);
      })
      .catch(error => {
        console.error('Error fetching customers:', error);
        showAlert('danger', 'Failed to fetch customers.');
      });
  }, []);

  useEffect(() => {
    const results = customers.filter(customer =>
      customer.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCustomers(results);
    setCurrentPage(1); // Reset to the first page when search term changes
  }, [searchTerm, customers]);

  const handleEdit = (id) => {
    const customer = customers.find(customer => customer.customer_id === id);
    setCustomerToEdit(customer);
    setEditModalVisible(true);
  };

  const handleUpdateCustomer = (updatedCustomer) => {
    fetch(`http://206.189.134.171:8000/customer/${updatedCustomer.customer_id}`, {
      method: 'PUT',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedCustomer),
    })
      .then(response => response.json())
      .then(() => {
        const updatedCustomers = customers.map(customer =>
          customer.customer_id === updatedCustomer.customer_id ? updatedCustomer : customer
        );
        setCustomers(updatedCustomers);
        setFilteredCustomers(updatedCustomers);
        setEditModalVisible(false);
        showAlert('success', 'Customer updated successfully.');
      })
      .catch(error => {
        console.error('Error updating customer:', error);
        showAlert('danger', 'Failed to update customer.');
      });
  };

  const handleDelete = (id) => {
    setCustomerToDelete(id);
    setDeleteModalVisible(true);
  };

  const handleConfirmDelete = () => {
    fetch(`http://206.189.134.171:8000/customer/${customerToDelete}`, {
      method: 'DELETE',
      headers: {
        'accept': 'application/json',
      },
    })
      .then(() => {
        const updatedCustomers = customers.filter(customer => customer.customer_id !== customerToDelete);
        setCustomers(updatedCustomers);
        setFilteredCustomers(updatedCustomers);
        setDeleteModalVisible(false);
        showAlert('success', 'Customer deleted successfully.');
      })
      .catch(error => {
        console.error('Error deleting customer:', error);
        showAlert('danger', 'Failed to delete customer.');
      });
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const handleCloseEditModal = () => {
    setEditModalVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerToEdit(prevCustomer => ({ ...prevCustomer, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (customerToEdit) {
      handleUpdateCustomer(customerToEdit);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  // Calculate pagination values
  const indexOfLastCustomer = currentPage * itemsPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
  const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);
  const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);

  return (
    <>
      <div id='wrapper'>
        <div class="iq-sidebar sidebar-default ">
          <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
            <a class="header-logo m-auto p-auto">
              {/* <img src="./assets/images/page-img/green-mark-logo.png" class="img-fluid rounded-normal light-logo" alt="logo" /> */}
              <h5 class="logo-title light-logo text-success ">Green</h5>
              <h5 class="logo-title light-logo text-secondary">-Mark</h5>
            </a>
            <div class="iq-menu-bt-sidebar">
              <i class="las la-bars wrapper-menu"></i>
            </div>
          </div>

          <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <nav class="iq-sidebar-menu ">
              <ul id="iq-sidebar-toggle" class="iq-menu">
                <li class="">
                  <a href="/dashboard" class="svg-icon">
                    <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span class="ml-4">Dashboards</span>
                  </a>
                </li>
                <li class="">
                  <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <span class="ml-4">Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="category" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addcategory">
                        <i class="las la-minus"></i><span>Add Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listcategory">
                        <i class="las la-minus"></i><span>List of Category</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                    </svg>
                    <span class="ml-4">Sub-Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addsubcategory">
                        <i class="las la-minus"></i><span>Add Sub-Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listsubcategory">
                        <i class="las la-minus"></i><span>Sub-Category List</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span class="ml-4">Products</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="product" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addproduct">
                        <i class="las la-minus"></i><span>Add Product</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listproduct">
                        <i class="las la-minus"></i><span>List of Products</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="/listorder" class="svg-icon">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                    </svg>
                    <span class="ml-4">Orders</span>
                  </a>
                </li>
                <li class="">
                  <a href="#updates" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M4 4h16v2H4z"></path>
                      <path d="M4 12h16v2H4z"></path>
                      <path d="M4 20h16v2H4z"></path>
                    </svg>
                    <span class="ml-4">Updates</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="updates" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/pricebook">
                        <i class="las la-minus"></i><span>Price Book</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/updateinventory">
                        <i class="las la-minus"></i><span>Update Inventory</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="active">
                  <a href="#people" class="collapsed" data-toggle="collapse" aria-expanded="true">
                    <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span class="ml-4">People</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="people" class="iq-submenu collapse show" data-parent="#iq-sidebar-toggle">
                    <li class="active">
                      <a href="/customer">
                        <i class="las la-minus"></i><span>Customers</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/user">
                        <i class="las la-minus"></i><span>Users</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/supplier">
                        <i class="las la-minus"></i><span>Suppliers</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <NavBar />
        <Header />
        <div className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 my-4">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                  <div>
                    <h4 className="m-0 p-0 text-primary my-2">Customer List</h4>
                  </div>
                  <a href="/addcustomer" className="btn btn-primary m-0 add-list my-2"><i className="las la-plus mr-3"></i>Add customer</a>
                </div>
                <div className='row p-3 mt-2'>
                  <div className=" col-md-8 input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="ri-search-line"></i>
                      </span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search customers..."
                      value={searchTerm} name='first_name' onChange={handleSearch} />
                  </div>
                  <div className='col-md-4'>
                    <select id="itemsPerPage" className="custom-select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                      <option value="5">05 data / page</option>
                      <option value="10">10 data / page</option>
                      <option value="15">15 data / page</option>
                      <option value="20">20 data / page</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="table-responsive rounded mb-3">
                  <table className="data-table table mb-0 tbl-server-info">
                    <thead className="bg-white text-uppercaser">
                      <tr className="ligth ligth-data">
                        <th>Customer ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email ID</th>
                        <th>Phone No.</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Pin-code</th>
                        <th>Address</th>
                        <th>Gender</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="ligth-bodyr">
                      {currentCustomers.length > 0 ? (
                        currentCustomers.map(customer => (
                          <tr key={customer.customer_id}>
                            <td>{customer.customer_id}</td>
                            <td>{customer.first_name}</td>
                            <td>{customer.last_name}</td>
                            <td>{customer.email}</td>
                            <td>{customer.mobile_number}</td>
                            <td>{customer.city}</td>
                            <td>{customer.state}</td>
                            <td>{customer.pincode}</td>
                            <td>{customer.address}</td>
                            <td>{customer.gender}</td>
                            <td>
                              <div className="d-flex align-items-center list-action">
                                <a className="badge bg-warning mr-2" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => handleEdit(customer.customer_id)}>
                                  <i className="ri-pencil-line mr-0"></i>
                                </a>
                                <a className="badge bg-danger mr-2" data-toggle="tooltip" data-placement="top" title="Delete" onClick={() => handleDelete(customer.customer_id)}>
                                  <i className="ri-delete-bin-line mr-0"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="11">No customers available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {editModalVisible && (
            <div className="modal" style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title text-orange">Edit Customer</h5>
                    <button type="button" className="close" onClick={handleCloseEditModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ overflow: 'auto', maxHeight: '48vh' }}>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="customer_id">Customer ID</label>
                        <input type="text" id="customer_id" name="customer_id" className="form-control"
                          value={customerToEdit?.customer_id || ''} disabled />
                      </div>
                      <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input type="text" id="first_name" name="first_name" className="form-control"
                          value={customerToEdit?.first_name || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input type="text" id="last_name" name="last_name" className="form-control"
                          value={customerToEdit?.last_name || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" className="form-control"
                          value={customerToEdit?.email || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="mobile_number">Phone No.</label>
                        <input type="number" id="mobile_number" name="mobile_number" className="form-control"
                          value={customerToEdit?.mobile_number || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input type="text" id="city" name="city" className="form-control"
                          value={customerToEdit?.city || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="state">State</label>
                        <input type="text" id="state" name="state" className="form-control"
                          value={customerToEdit?.state || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="pincode">Pin-code</label>
                        <input type="text" id="pincode" name="pincode" className="form-control"
                          value={customerToEdit?.pincode || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <textarea id="address" name="address" className="form-control"
                          value={customerToEdit?.address || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="gender">Gender</label>
                        <select id="gender" name="gender" className="custom-select"
                          value={customerToEdit?.gender || ''} onChange={handleChange}>
                          <option value="" disabled>Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="modal-footer p-0 pt-3">
                        <button type="button" className="btn btn-secondary" onClick={handleCloseEditModal}>Cancel</button>
                        <button type="submit" className="btn btn-primary">Save changes</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Delete Modal */}
          <div className={`modal fade ${deleteModalVisible ? 'show' : ''}`} id="deleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="deleteConfirmationLabel" aria-hidden={!deleteModalVisible} style={{ display: deleteModalVisible ? 'block' : 'none' }}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-orange" id="deleteConfirmationLabel">Confirm Deletion</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseDeleteModal}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to delete this customer?</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleCloseDeleteModal}>Cancel</button>
                  <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-end mx-5">
            <div className="p-3">
              <button className="badge badge-secondary mr-1" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                <i className="fas fa-arrow-left"></i>
              </button>
              <button className="badge badge-secondary ml-1" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} >
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>
            <div className="p-3">
              Page {currentPage} of {totalPages}
            </div>
          </div>
          {alert.visible && (
            <div className={`alert alert-${alert.type} border-dark`} role="alert">
              <div className="iq-alert-icon text-danger">
                <i className={`ri-${alert.type === 'success' ? 'check-line' : 'information-line'}`}></i>
              </div>
              <div className="iq-alert-text text-orange">{alert.message}</div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Customer;
