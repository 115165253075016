import React from 'react';

function Footer() {
  return (
    <div>
      <footer class="iq-footer">
        <div class="container-fluid">
          <div class="card  text-center mx-auto pt-3">
                <p>Copyright © Designed &amp; Developed by <a href="https://datamoo.ai/" target="blank">DataMoo AI</a> 2024</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;