import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BaseRoutes } from './components/basics/BaseRoutes';

import SignIn from './components/authscreens/SignIn';
import SignUp from './components/authscreens/SignUp';
import ForgotPassword from './components/authscreens/ForgotPassword';
import SuccessMail from './components/authscreens/SuccessMail';
import PreLoader from './components/layouts/Preloader';
import Dashboard from './Screens/Dashboard';
import AddCategory from './Screens/Categories/AddCategory';
import ListCategory from './Screens/Categories/ListCategory';
import AddProduct from './Screens/Products/AddProduct';
import ListProduct from './Screens/Products/ListProduct';
import User from './Screens/People/User';
import AddUser from './Screens/People/AddUser';
import Customer from './Screens/People/Customer';
import AddCustomer from './Screens/People/AddCustomer';
import Supplier from './Screens/People/Supplier';
import AddSupplier from './Screens/People/AddSupplier';
import Profile from './Screens/Profile';
import Invoice from './Screens/Payment/Invoice';
import AddSale from './Screens/Sales/AddSale';
import ListSale from './Screens/Sales/ListSale';
import UpdateInventory from './Screens/Updates/InventoryDetails';
import PriceBook from './Screens/Updates/PriceBook';
import InventoryDetails from "./Screens/Updates/UpdateInventory";
import AddPriceBook from './Screens/Updates/AddPriceBook';
import AddSubCategory from './Screens/Sub-Category/AddSubCategory';
import ListSubCategory from './Screens/Sub-Category/ListSubCategory';
import OrderOverview from './Screens/Order/OrderOverview';
import ListOrder from './Screens/Order/ListOrder';

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <PreLoader/>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path={BaseRoutes.Signin} element={<SignIn />}></Route>
            <Route path={BaseRoutes.SignUp} element={<SignUp />}></Route>
            <Route path={BaseRoutes.ForgotPassword} element={<ForgotPassword />}></Route>
            <Route path={BaseRoutes.Profile} element={<Profile />}></Route>
            <Route path={BaseRoutes.SuccessMail} element={<SuccessMail />}></Route>
            <Route path={BaseRoutes.DashBoard} element={<Dashboard />}></Route>
            <Route path={BaseRoutes.AddCategory} element={<AddCategory />}></Route>
            <Route path={BaseRoutes.ListCategory} element={<ListCategory />}></Route>
            <Route path={BaseRoutes.AddSubCategory} element={<AddSubCategory />}></Route>
            <Route path={BaseRoutes.ListSubCategory} element={<ListSubCategory />}></Route>
            <Route path={BaseRoutes.AddProduct} element={<AddProduct />}></Route>
            <Route path={BaseRoutes.ListProduct} element={<ListProduct />}></Route>
            <Route path={BaseRoutes.AddSale} element={<AddSale />}></Route>
            <Route path={BaseRoutes.ListSale} element={<ListSale />}></Route>
            <Route path={BaseRoutes.PriceBook} element={<PriceBook />}></Route>
            <Route path={BaseRoutes.AddPriceBook} element={<AddPriceBook />}></Route>
            <Route path={BaseRoutes.UpdateInventory} element={<UpdateInventory />}></Route>
            <Route path={BaseRoutes.InventoryDetails} element={<InventoryDetails />}></Route>
            <Route path={BaseRoutes.User} element={<User />}></Route>
            <Route path={BaseRoutes.AddUser} element={<AddUser />}></Route>
            <Route path={BaseRoutes.Customer} element={<Customer />}></Route>
            <Route path={BaseRoutes.AddCustomer} element={<AddCustomer />}></Route>
            <Route path={BaseRoutes.Supplier} element={<Supplier />}></Route>
            <Route path={BaseRoutes.AddSupplier} element={<AddSupplier />}></Route>
            <Route path={BaseRoutes.Invoice} element={<Invoice />}></Route>
            <Route path={BaseRoutes.OrderOverview} element={<OrderOverview />}></Route>
            <Route path={BaseRoutes.ListOrder} element={<ListOrder />}></Route>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
