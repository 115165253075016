import React from "react";

function NavBar() {
  return (
    <div>
      <div className="iq-top-navbar py-0 my-0">
        <div className="iq-navbar-custom py-0 my-0">
          <nav className="navbar navbar-expand-lg navbar-light py-0 my-0">
            <div className="iq-search-bar device-search">
              {/* <form action="" className="searchbox">
                <a className="search-link" href="#"><i className="ri-search-line"></i></a>
                <input type="text" className="text search-input" placeholder="Search here..." />
              </form> */}
            </div>
            <div className="d-flex align-items-center">
                  <i class="las la-bars wrapper-menu"></i>
              <div className="" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto navbar-list align-items-center">
                  {/* <li className="nav-item nav-icon search-content">
                    <a href="#" className="search-toggle rounded" id="dropdownSearch"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="ri-search-line"></i>
                    </a>
                    <div className="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
                      <form action="" className="searchbox p-2">
                        <div className="form-group mb-0 position-relative">
                          <input type="text" className="text search-input font-size-12" placeholder="type here to search..." />
                          <a href="#" className="search-link"><i className="las la-search"></i></a>
                        </div>
                      </form>
                    </div>
                  </li> */}
                  <li className="nav-item nav-icon dropdown">
                    <a href="#" className="search-toggle dropdown-toggle" id="dropdownMenuButton2" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" className="feather feather-mail">
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                        <polyline points="22,6 12,13 2,6"></polyline>
                      </svg>
                      <span className="bg-primary"></span>
                    </a>
                    <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton2">
                      <div className="card shadow-none m-0">
                        <div className="card-body p-0">
                          <div className="cust-title p-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">All Messages</h5>
                              <a className="badge badge-primary badge-card" href="#">3</a>
                            </div>
                          </div>
                          <div className="px-3 pt-0 pb-0 sub-card">
                            <a href="#" className="iq-sub-card">
                              <div className="media align-items-center cust-card py-3 border-bottom">
                                <div className="">
                                  <img className="avatar-50 rounded-small" src="../assets/images/user/01.jpg" alt="01" />
                                </div>
                                <div className="media-body ml-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="mb-0">Ashwin</h6>
                                    <small className="text-dark">Order No.<b> 29</b></small>
                                  </div>
                                  <small className="mb-0">Item : <b>Cream Milk</b></small><br/>
                                  <small className="mb-0">Quantity : <b>4 Litres</b></small>
                                </div>
                              </div>
                            </a>
                            <a href="#" className="iq-sub-card">
                              <div className="media align-items-center cust-card py-3 border-bottom">
                                <div className="">
                                  <img className="avatar-50 rounded-small" src="../assets/images/user/02.jpg" alt="02" />
                                </div>
                                <div className="media-body ml-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="mb-0">Nisha</h6>
                                    <small className="text-dark">Order No.<b> 36</b></small>
                                  </div>
                                  <small className="mb-0">Item : <b>Carrots</b></small><br/>
                                  <small className="mb-0">Quantity : <b>8 Kg</b></small>
                                </div>
                              </div>
                            </a>
                            <a href="#" className="iq-sub-card">
                              <div className="media align-items-center cust-card py-3">
                                <div className="">
                                  <img className="avatar-50 rounded-small" src="../assets/images/user/03.jpg" alt="03" />
                                </div>
                                <div className="media-body ml-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="mb-0">Priya</h6>
                                    <small className="text-dark">Order No.<b> 43</b></small>
                                  </div>
                                  <small className="mb-0">Item : <b>Apples</b></small><br/>
                                  <small className="mb-0">Quantity : <b>6 Kg</b></small>
                                </div>
                              </div>
                            </a>
                          </div>
                          <a className="right-ic btn btn-primary btn-block position-relative p-2" href="#" role="button">
                            View All
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item nav-icon dropdown caption-content">
                    <a href="#" className="search-toggle dropdown-toggle" id="dropdownMenuButton4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img src="../assets/images/user/1.png" className="img-fluid rounded" alt="user" />
                    </a>
                    <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <div className="card shadow-none m-0">
                        <div className="card-body p-0 text-center">
                          <div className="media-body profile-detail text-center">
                            <img src="../assets/images/page-img/profile-bg.jpg" alt="profile-bg" className="rounded-top img-fluid mb-4" />
                            <img src="../assets/images/user/1.png" alt="profile-img" className="rounded profile-img img-fluid avatar-70" />
                          </div>
                          <div className="p-3">
                            <h5 className="mb-1 text-primary">Rithick</h5>
                            <p className="mb-0">datamoo.ai</p>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                              <a href="/profile" className="btn btn-success border mr-2">Profile</a>
                              <a href="/" className="btn btn-danger border">Sign Out</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default NavBar;