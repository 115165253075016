import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

const AddPriceBook = () => {
    const [priceListType, setPriceListType] = useState('');
    const [description, setDescription] = useState('');
    const [percentage, setPercentage] = useState('');
    const [roundOffTo, setRoundOffTo] = useState('');
    const [name, setName] = useState('');
    const [items, setItems] = useState([{ id: 1, name: '', price: '' }]);
    const [editingIndex, setEditingIndex] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/pricebook');
    };

    const handleAddItem = () => {
        setItems([...items, { id: items.length + 1, name: '', price: '' }]);
        setEditingIndex(items.length); // Start editing the new item
    };

    const handleItemChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setItems(newItems);
    };

    const handleEditItem = (index) => {
        setEditingIndex(index);
    };

    const handleSaveItem = () => {
        setEditingIndex(null);
    };

    const handleDeleteItem = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
        if (index === editingIndex) {
            setEditingIndex(null);
        } else if (index < editingIndex) {
            setEditingIndex(editingIndex - 1);
        }
    };

    return (
        <>
            <div id='wrapper'>
                {/* <SideBar/> */}
                <div class="iq-sidebar sidebar-default ">

                    <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
                        <a class="header-logo m-auto p-auto">
                            {/* <img src="./assets/images/page-img/green-mark-logo.png" class="img-fluid rounded-normal light-logo" alt="logo" /> */}
                            <h5 class="logo-title light-logo text-success ">Green</h5>
                            <h5 class="logo-title light-logo text-secondary">-Mark</h5>
                        </a>
                        <div class="iq-menu-bt-sidebar">
                            <i class="las la-bars wrapper-menu"></i>
                        </div>
                    </div>

                    <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        <nav class="iq-sidebar-menu ">
                            <ul id="iq-sidebar-toggle" class="iq-menu">
                                <li class="">
                                    <a href="/dashboard" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg>
                                        <span class="ml-4">Dashboards</span>
                                    </a>
                                </li>
                                <li class="">
                                    <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                        <span class="ml-4">Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="product" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addcategory">
                                                <i class="las la-minus"></i><span>Add Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listcategory">
                                                <i class="las la-minus"></i><span>List of Category</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                                        </svg>
                                        <span class="ml-4">Sub-Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addsubcategory">
                                                <i class="las la-minus"></i><span>Add Sub-Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listsubcategory">
                                                <i class="las la-minus"></i><span>Sub-Category List</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="9" cy="21" r="1"></circle>
                                            <circle cx="20" cy="21" r="1"></circle>
                                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                        </svg>
                                        <span class="ml-4">Products</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="product" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addproduct">
                                                <i class="las la-minus"></i><span>Add Product</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listproduct">
                                                <i class="las la-minus"></i><span>List of Products</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="/listorder" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                                        </svg>
                                        <span class="ml-4">Orders</span>
                                    </a>
                                </li>
                                <li class="active">
                                    <a href="#updates" class="collapsed" data-toggle="collapse" aria-expanded="true">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M4 4h16v2H4z"></path>
                                            <path d="M4 12h16v2H4z"></path>
                                            <path d="M4 20h16v2H4z"></path>
                                        </svg>
                                        <span class="ml-4">Updates</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="updates" class="iq-submenu collapse show" data-parent="#iq-sidebar-toggle">
                                        <li class="active">
                                            <a href="/pricebook">
                                                <i class="las la-minus"></i><span>Price Book</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/updateinventory">
                                                <i class="las la-minus"></i><span>Update Inventory</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#people" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="9" cy="7" r="4"></circle>
                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                        </svg>
                                        <span class="ml-4">People</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="people" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/customer">
                                                <i class="las la-minus"></i><span>Customer</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/user">
                                                <i class="las la-minus"></i><span>Users</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/supplier">
                                                <i class="las la-minus"></i><span>Suppliers</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <NavBar />
                <Header />
                <div className="content-page">
                    <div className="container-fluid add-form-list">
                        <div className="row mt-4">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="header-title d-flex justify-content-between align-items-center">
                                            <h4 className="card-title text-primary">Add Price-Book</h4>
                                            <a className="btn btn-primary m-0 add-list" href="/pricebook">
                                                <i className="las la-eye mr-3"></i>View PriceBook
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className='row'>
                                                <div className='col-xl-12'>
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name</label>
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            className="form-control"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-xl-12'>
                                                    <div className="form-group">
                                                        <label>Price List Type</label>
                                                        <div className="form-check">
                                                            <input
                                                                type="radio"
                                                                id="allItems"
                                                                name="priceListType"
                                                                value="All Items"
                                                                checked={priceListType === 'All Items'}
                                                                onChange={(e) => setPriceListType(e.target.value)}
                                                                className="form-check-input"
                                                            />
                                                            <label htmlFor="allItems" className="form-check-label">All Items</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                type="radio"
                                                                id="individualItems"
                                                                name="priceListType"
                                                                value="Individual Items"
                                                                checked={priceListType === 'Individual Items'}
                                                                onChange={(e) => setPriceListType(e.target.value)}
                                                                className="form-check-input"
                                                            />
                                                            <label htmlFor="individualItems" className="form-check-label">Individual Items</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {priceListType === 'All Items' && (
                                                    <>
                                                        <div className='col-xl-6'>
                                                            <div className="form-group">
                                                                <label htmlFor="percentage">Percentage</label>
                                                                <input
                                                                    type="number"
                                                                    id="percentage"
                                                                    name="percentage"
                                                                    className="form-control"
                                                                    value={percentage}
                                                                    onChange={(e) => setPercentage(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-xl-6'>
                                                            <div className="form-group">
                                                                <label htmlFor="roundOffTo">Round Off To</label>
                                                                <input
                                                                    type="number"
                                                                    id="roundOffTo"
                                                                    name="roundOffTo"
                                                                    className="form-control"
                                                                    value={roundOffTo}
                                                                    onChange={(e) => setRoundOffTo(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {priceListType === 'Individual Items' && (
                                                    <>
                                                        <div className='col-xl-12'>
                                                            <div className="form-group">
                                                                <label htmlFor="description">Description</label>
                                                                <textarea
                                                                    id="description"
                                                                    name="description"
                                                                    className="form-control"
                                                                    value={description}
                                                                    onChange={(e) => setDescription(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-xl-12'>
                                                            <div className="table-responsive rounded mb-3 mt-4">
                                                                <table className="data-table table mb-0 tbl-server-info">
                                                                    <thead className="bg-white text-uppercase">
                                                                        <tr className="ligth ligth-data">
                                                                            <th>Item Name</th>
                                                                            <th>Price</th>
                                                                            <th>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="ligth-body">
                                                                        {items.map((item, index) => (
                                                                            <tr key={item.id}>
                                                                                <td>
                                                                                    {editingIndex === index ? (
                                                                                        <input
                                                                                            type="text"
                                                                                            value={item.name}
                                                                                            onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                                                                                            className="form-control"
                                                                                        />
                                                                                    ) : (
                                                                                        item.name
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {editingIndex === index ? (
                                                                                        <input
                                                                                            type="number"
                                                                                            value={item.price}
                                                                                            onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                                                                                            className="form-control"
                                                                                        />
                                                                                    ) : (
                                                                                        item.price
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {editingIndex === index ? (
                                                                                        <>
                                                                                            <button type="button" onClick={handleSaveItem} className="btn btn-success">Save</button>
                                                                                            <button type="button" onClick={() => handleDeleteItem(index)} className="btn btn-danger ml-2">Delete</button>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <button type="button" onClick={() => handleEditItem(index)} className="btn btn-secondary">Edit</button>
                                                                                            <button type="button" onClick={() => handleDeleteItem(index)} className="btn btn-danger ml-2">Delete</button>
                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <button type="button" onClick={handleAddItem} className="btn btn-secondary mt-3">Add Item</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className='col-xl-12'>
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default AddPriceBook;
