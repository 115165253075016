import React, { useEffect, useState } from 'react';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

function ListCategory() {
  const [listCategory, setListCategory] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [categoryToDeleteName, setCategoryToDeleteName] = useState('');
  const [alert, setAlert] = useState({ type: '', message: '', visible: false });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const showAlert = (type, message) => {
    setAlert({ type, message, visible: true });
    setTimeout(() => setAlert({ ...alert, visible: false }), 3000);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch('http://206.189.134.171:8000/categorielist/', {
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setListCategory(data);
        showAlert('success', 'Categories updated successfully');
      } else {
        showAlert('danger', 'Failed to load categories');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      showAlert('danger', 'An error occurred while fetching categories');
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleEdit = (id) => {
    const category = listCategory.find((category) => category.id === id);
    setCategoryToEdit(category);
    setEditModalVisible(true);
  };

  const handleUpdateCategory = async () => {

    const apiUrl = `http://206.189.134.171:8000/categories/${categoryToEdit.id}?cat_id=${categoryToEdit.id}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          category_id: categoryToEdit.category_id,
          category_name: categoryToEdit.category_name,
          category_image: categoryToEdit.category_image,
        }),
      });

      if (response.ok) {
        const updatedListCategory = listCategory.map((category) =>
          category.category_id === categoryToEdit.category_id ? categoryToEdit : category
        );
        setListCategory(updatedListCategory);
        setEditModalVisible(false);
        showAlert('success', 'Category updated successfully');
      } else {
        console.error('Failed to update category. Status Code:', response.status);
        showAlert('danger', 'Failed to update category');
      }
    } catch (error) {
      console.error('Error updating category:', error);
      showAlert('danger', 'An error occurred while updating the category');
    }
  };

  const handleDelete = (name) => {
    setCategoryToDeleteName(name);
    setDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`http://206.189.134.171:8000/categories/${categoryToDeleteName}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        setDeleteModalVisible(false);
        setCategoryToDeleteName('');
        fetchCategories();
        showAlert('success', 'Category deleted successfully');
      } else {
        showAlert('danger', 'Failed to delete category');
      }
    } catch (error) {
      console.error('Error deleting category:', error);
      showAlert('danger', 'An error occurred while deleting the category');
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalVisible(false);
    setCategoryToDeleteName('');
  };

  const handleCloseEditModal = () => {
    setEditModalVisible(false);
    setCategoryToEdit(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryToEdit((prevCategory) => ({ ...prevCategory, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (categoryToEdit) {
      handleUpdateCategory();
    }
  };

  const [searchFields, setSearchFields] = useState({
    category_name: "",
  });

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchFields({
      ...searchFields,
      [name]: value
    });
  };

  const filteredCategories = listCategory.filter((category) => {
    return (
      (searchFields.category_name === "" || category.category_name.toLowerCase().includes(searchFields.category_name.toLowerCase()))
    );
  });

  const totalItems = filteredCategories.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentCategories = filteredCategories.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <>
      <div id="wrapper">
        <div class="iq-sidebar sidebar-default ">
          <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
            <a class="header-logo m-auto p-auto">
              {/* <img src="./assets/images/page-img/green-mark-logo.png" class="img-fluid rounded-normal light-logo" alt="logo" /> */}
              <h5 class="logo-title light-logo text-success ">Green</h5>
              <h5 class="logo-title light-logo text-secondary">-Mark</h5>
            </a>
            <div class="iq-menu-bt-sidebar">
              <i class="las la-bars wrapper-menu"></i>
            </div>
          </div>
          <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <nav class="iq-sidebar-menu ">
              <ul id="iq-sidebar-toggle" class="iq-menu">
                <li class="">
                  <a href="/dashboard" class="svg-icon">
                    <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span class="ml-4">Dashboards</span>
                  </a>
                </li>
                <li class="active">
                  <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="true">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <span class="ml-4">Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="category" class="iq-submenu collapse show " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addcategory">
                        <i class="las la-minus"></i><span>Add Category</span>
                      </a>
                    </li>
                    <li class="active">
                      <a href="/listcategory">
                        <i class="las la-minus"></i><span>List of Category</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                    </svg>
                    <span class="ml-4">Sub-Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addsubcategory">
                        <i class="las la-minus"></i><span>Add Sub-Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listsubcategory">
                        <i class="las la-minus"></i><span>Sub-Category List</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span class="ml-4">Products</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="product" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addproduct">
                        <i class="las la-minus"></i><span>Add Product</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listproduct">
                        <i class="las la-minus"></i><span>List of Products</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="/listorder" class="svg-icon">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                    </svg>
                    <span class="ml-4">Orders</span>
                  </a>
                </li>
                <li class="">
                  <a href="#updates" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M4 4h16v2H4z"></path>
                      <path d="M4 12h16v2H4z"></path>
                      <path d="M4 20h16v2H4z"></path>
                    </svg>
                    <span class="ml-4">Updates</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="updates" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/pricebook">
                        <i class="las la-minus"></i><span>Price Book</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/updateinventory">
                        <i class="las la-minus"></i><span>Update Inventory</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#people" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span class="ml-4">People</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="people" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/customer">
                        <i class="las la-minus"></i><span>Customers</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/user">
                        <i class="las la-minus"></i><span>Users</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/supplier">
                        <i class="las la-minus"></i><span>Suppliers</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <NavBar />
        <Header />
        <div className="content-page">
          <div className="container-fluid add-form-list">
            <div className="row">
              <div className="col-lg-12 my-4">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div>
                    <h4 className="m-0 p-0 text-primary my-2">Category List</h4>
                  </div>
                  <a className="btn btn-primary m-0 add-list my-2" href="/addcategory">
                    <i className="las la-plus mr-3"></i>Add Category
                  </a>
                </div>
                <div className='row p-3 mt-2'>
                  <div className="col-md-8 input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="ri-search-line"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search by Category Name"
                      name="category_name" value={searchFields.category_name} onChange={handleSearchChange} />
                  </div>
                  <div className='col-md-4'>
                    <select id="itemsPerPage" className="custom-select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                      <option value="5">05 data / page</option>
                      <option value="10">10 data / page</option>
                      <option value="15">15 data / page</option>
                      <option value="20">20 data / page</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="table-responsive rounded mb-3">
                  <table className="data-table table mb-0 tbl-server-info">
                    <thead className="bg-white text-uppercase">
                      <tr className="ligth ligth-data">
                        <th>Category ID</th>
                        <th>Category Image</th>
                        <th>Category Name</th>
                        <th>Category Code</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="ligth-body">
                      {currentCategories.length > 0 ? (
                        currentCategories.map((category) => (
                          <tr key={category.category_id}>
                            <td>{category.id}</td>
                            <td> <img src={category.category_image} alt={category.category_name} className="img-fluid rounded avatar-50" /> </td>
                            <td>{category.category_name}</td>
                            <td>{category.category_id}</td>
                            <td>
                              <div className="d-flex align-items-center list-action">
                                <a className="badge bg-warning mr-2" data-toggle="tooltip" data-placement="top"
                                  title="Edit" onClick={() => handleEdit(category.id)} >
                                  <i className="ri-pencil-line mr-0"></i>
                                </a>
                                <a className="badge bg-danger mr-2" data-toggle="tooltip" data-placement="top"
                                  title="Delete" onClick={() => handleDelete(category.category_name)} >
                                  <i className="ri-delete-bin-line mr-0"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr><td colSpan="5">No Category found</td></tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {editModalVisible && categoryToEdit && (
            <div className="modal pt-5 mt-5" style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title text-orange">Edit Category</h5>
                    <button type="button" className="close" onClick={handleCloseEditModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ overflow: 'auto', maxHeight: '48vh' }}>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="category_id">Category ID</label>
                        <input type="text" id="id" name="id" className="form-control"
                          value={categoryToEdit.id || ''} onChange={handleChange} disabled />
                      </div>
                      <div className="form-group">
                        <label htmlFor="category_id">Category Code</label>
                        <input type="text" id="category_id" name="category_id" className="form-control"
                          value={categoryToEdit.category_id || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="category_name">Category Name</label>
                        <input type="text" id="category_name" name="category_name" className="form-control"
                          value={categoryToEdit.category_name || ''} onChange={handleChange} />
                      </div>
                      <div className="modal-footer p-0 pt-3">
                        <button type="button" className="btn btn-secondary" onClick={handleCloseEditModal}>Cancel</button>
                        <button type="submit" className="btn btn-primary">Save changes</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {deleteModalVisible && (
            <div className="modal" style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title text-primary">Delete Category</h5>
                    <button type="button" className="close" onClick={handleCloseDeleteModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to delete this <b className='text-dark'>Category</b> ?</p>
                    <p className='text-orange'>This will delete all <b className='text-danger'>Product & Sub-Categories</b> related to it !!</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={handleCloseDeleteModal}>Cancel</button>
                    <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex flex-wrap justify-content-end mx-5">
            <div className="p-3">
              <button className="badge badge-secondary mr-1" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                <i className="fas fa-arrow-left"></i>
              </button>
              <button className="badge badge-secondary ml-1" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} >
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>
            <div className="p-3">
              Page {currentPage} of {totalPages}
            </div>
          </div>
          {alert.visible && (
            <div className={`alert alert-${alert.type} border-dark`} role="alert">
              <div className="iq-alert-icon text-danger">
                <i className={`ri-${alert.type === 'success' ? 'check-line' : 'information-line'}`}></i>
              </div>
              <div className="iq-alert-text text-orange">{alert.message}</div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ListCategory;
