
import React, { useState } from 'react';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

function Invoice() {

  return (
    <>
      <div id='wrapper'>
        {/* <SideBar/> */}
        <div className="iq-sidebar sidebar-default ">

          <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
            <a class="header-logo m-auto p-auto">
              {/* <img src="./assets/images/page-img/green-mark-logo.png" class="img-fluid rounded-normal light-logo" alt="logo" /> */}
              <h5 class="logo-title light-logo text-success ">Green</h5>
              <h5 class="logo-title light-logo text-secondary">-Mark</h5>
            </a>
            <div className="iq-menu-bt-sidebar">
              <i className="las la-bars wrapper-menu"></i>
            </div>
          </div>

          <div className="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <nav className="iq-sidebar-menu ">
              <ul id="iq-sidebar-toggle" className="iq-menu">
                <li className="">
                  <a href="/dashboard" className="svg-icon">
                    <svg className="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span className="ml-4">Dashboards</span>
                  </a>
                </li>
                <li className="">
                  <a href="#category" className="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg className="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <span className="ml-4">Categories</span>
                    <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="category" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li className="">
                      <a href="/addcategory">
                        <i className="las la-minus"></i><span>Add Category</span>
                      </a>
                    </li>
                    <li className="">
                      <a href="/listcategory">
                        <i className="las la-minus"></i><span>List of Category</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                    </svg>
                    <span class="ml-4">Sub-Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addsubcategory">
                        <i class="las la-minus"></i><span>Add Sub-Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listsubcategory">
                        <i class="las la-minus"></i><span>Sub-Category List</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="">
                  <a href="#product" className="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg className="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span className="ml-4">Products</span>
                    <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="product" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li className="">
                      <a href="/addproduct">
                        <i className="las la-minus"></i><span>Add Product</span>
                      </a>
                    </li>
                    <li className="">
                      <a href="/listproduct">
                        <i className="las la-minus"></i><span>List of Products</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="/listorder" class="svg-icon">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                    </svg>
                    <span class="ml-4">Orders</span>
                  </a>
                </li>
                <li class="">
                  <a href="#updates" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M4 4h16v2H4z"></path>
                      <path d="M4 12h16v2H4z"></path>
                      <path d="M4 20h16v2H4z"></path>
                    </svg>
                    <span class="ml-4">Updates</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="updates" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/pricebook">
                        <i class="las la-minus"></i><span>Price Book</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/updateinventory">
                        <i class="las la-minus"></i><span>Update Inventory</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="">
                  <a href="#people" className="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg className="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span className="ml-4">People</span>
                    <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="people" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li className="">
                      <a href="/customer">
                        <i className="las la-minus"></i><span>Customer</span>
                      </a>
                    </li>
                    <li className="">
                      <a href="/user">
                        <i className="las la-minus"></i><span>Users</span>
                      </a>
                    </li>
                    <li className="">
                      <a href="/supplier">
                        <i className="las la-minus"></i><span>Suppliers</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <NavBar />
        <Header />
        <div className="content-page">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card card-block card-stretch card-height print rounded">
                  <div className="card-header d-flex justify-content-between bg-primary header-invoice">
                    <div className="iq-header-title">
                      <h4 className="card-title mb-0">Invoice - #1234567</h4>
                    </div>
                    <div className="invoice-btn">
                      <button type="button" className="btn btn-secondary mr-2"><i className="las la-print"></i> Print
                        Print</button>
                      <button type="button" className="btn btn-secondary"><i className="las la-file-download"></i>PDF</button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive-sm">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Order Date</th>
                                <th scope="col">Order Status</th>
                                <th scope="col">Order ID</th>
                                <th scope="col">Billing Address</th>
                                <th scope="col">Shipping Address</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Aug 02, 2024</td>
                                <td><span className="badge badge-danger">Unpaid</span></td>
                                <td>250028</td>
                                <td>
                                  <p className="mb-0">PO Box 16122 Collins Street West<br />Victoria 8007 Australia<br />
                                    Phone: +123 456 7890<br />
                                    Email: demo@example.com<br />
                                    Web: www.example.com
                                  </p>
                                </td>
                                <td>
                                  <p className="mb-0">PO Box 16122 Collins Street West<br />Victoria 8007 Australia<br />
                                    Phone: +123 456 7890<br />
                                    Email: demo@example.com<br />
                                    Web: www.example.com
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <h5 className="mb-3 text-primary">Order Summary</h5>
                        <div className="table-responsive-sm">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="text-center" scope="col">#</th>
                                <th scope="col">Item</th>
                                <th className="text-center" scope="col">Quantity</th>
                                <th className="text-center" scope="col">Price</th>
                                <th className="text-center" scope="col">Totals</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th className="text-center" scope="row">1</th>
                                <td>
                                  <h6 className="mb-0">Dairy</h6>
                                  <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                  </p>
                                </td>
                                <td className="text-center">5</td>
                                <td className="text-center">$120.00</td>
                                <td className="text-center"><b>$2,880.00</b></td>
                              </tr>
                              <tr>
                                <th className="text-center" scope="row">2</th>
                                <td>
                                  <h6 className="mb-0">Millets</h6>
                                  <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                  </p>
                                </td>
                                <td className="text-center">5</td>
                                <td className="text-center">$120.00</td>
                                <td className="text-center"><b>$2,880.00</b></td>
                              </tr>
                              <tr>
                                <th className="text-center" scope="row">3</th>
                                <td>
                                  <h6 className="mb-0">Vegetables</h6>
                                  <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                  </p>
                                </td>
                                <td className="text-center">5</td>
                                <td className="text-center">$120.00</td>
                                <td className="text-center"><b>$2,880.00</b></td>
                              </tr>
                              <tr>
                                <th className="text-center" scope="row">4</th>
                                <td>
                                  <h6 className="mb-0">Fruits</h6>
                                  <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                  </p>
                                </td>
                                <td className="text-center">5</td>
                                <td className="text-center">$120.00</td>
                                <td className="text-center"><b>$2,880.00</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="ml-3 mt-3 col-lg-3">
                        <h4 className="text-danger">Notes:</h4>
                        <p className="mb-0">It is a long established fact that a reader will be distracted by the readable content of a page
                          when looking
                          at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                          as opposed to using 'Content here, content here', making it look like readable English.</p>
                      </div>
                      <div className="offset-lg-1 col-lg-7">
                        <div className="or-detail rounded">
                          <div className="p-3">
                            <h5 className="mb-3 text-primary">Order Details</h5>
                            <div className="mb-2 d-flex justify-content-between">
                              <h6>Bank</h6>
                              <p>Threadneedle St</p>
                            </div>
                            <div className="mb-2 d-flex justify-content-between">
                              <h6>Acc. No</h6>
                              <p>12333456789</p>
                            </div>
                            <div className="mb-2 d-flex justify-content-between">
                              <h6>Due Date</h6>
                              <p>12 August 2020</p>
                            </div>
                            <div className="mb-2 d-flex justify-content-between">
                              <h6>Sub Total</h6>
                              <p>$4597.50</p>
                            </div>
                            <div className='mb-2 d-flex justify-content-between'>
                              <h6>Discount</h6>
                              <p>10%</p>
                            </div>
                          </div>
                          <div className="ttl-amt py-2 px-3 text-secondary d-flex justify-content-between align-items-center">
                            <h6>Total</h6>
                            <h3 className="text-secondary font-weight-700">$4137.75</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Invoice;
