import React, { useState } from 'react';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

function Supplier() {
  const [suppliers, setSuppliers] = useState([
    { id: 1, companyName: 'Fruits Supply', name: 'Max Conversion', email: 'max@gmail.com', phone: '0123456789', city: 'Petaling', country: 'USA', gstNo: '1234' },
    { id: 2, companyName: 'Footwear Supply', name: 'Paige Turner', email: 'paige@gmail.com', phone: '0125856789', city: 'Orlando', country: 'USA', gstNo: '1235' },
    { id: 3, companyName: 'Vegetable Supply', name: 'Jack Conversion', email: 'jack@gmail.com', phone: '0123456789', city: 'England', country: 'UK', gstNo: '1234' },
    { id: 4, companyName: 'Cream Supply', name: 'Rose Turner', email: 'rose@gmail.com', phone: '0125856789', city: 'NewYork', country: 'USA', gstNo: '1235' },
    { id: 5, companyName: 'Millets Supply', name: 'Tom Cruise', email: 'tom@gmail.com', phone: '0123456789', city: 'Chennai', country: 'India', gstNo: '1234' },
    { id: 6, companyName: 'Dairy Supply', name: 'Jerry Osborn', email: 'jerry@gmail.com', phone: '0125856789', city: 'Mumbai', country: 'India', gstNo: '1235' },
    { id: 7, companyName: 'Sweet Supply', name: 'Peter Parker', email: 'peter@gmail.com', phone: '0123456789', city: 'Africa', country: 'Africa', gstNo: '1234' },
    { id: 8, companyName: 'Beverage Supply', name: 'Henry Osborn', email: 'henry@gmail.com', phone: '0125856789', city: 'Ooty', country: 'India', gstNo: '1235' },
  ]);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [supplierToEdit, setsupplierToEdit] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [supplierToDelete, setsupplierToDelete] = useState(null);

  const handleEdit = (id) => {
    const supplier = suppliers.find(supplier => supplier.id === id);
    setsupplierToEdit(supplier);
    setEditModalVisible(true);
  };

  const handleUpdatesupplier = (updatedsupplier) => {
    const updatedsuppliers = suppliers.map(supplier =>
      supplier.id === updatedsupplier.id ? updatedsupplier : supplier
    );
    setSuppliers(updatedsuppliers);
    setEditModalVisible(false);
  };

  const handleDelete = (id) => {
    setsupplierToDelete(id);
    setDeleteModalVisible(true);
  };

  const handleConfirmDelete = () => {
    const updatedsuppliers = suppliers.filter(supplier => supplier.id !== supplierToDelete);
    setSuppliers(updatedsuppliers);
    setDeleteModalVisible(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const handleCloseEditModal = () => {
    setEditModalVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setsupplierToEdit(prevsupplier => ({ ...prevsupplier, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (supplierToEdit) {
      handleUpdatesupplier(supplierToEdit);
    }
  };

  return (
    <>
      <div id='wrapper'>
        {/* <SideBar/> */}
        <div class="iq-sidebar sidebar-default ">

          <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
            <a class="header-logo m-auto p-auto">
              {/* <img src="./assets/images/page-img/green-mark-logo.png" class="img-fluid rounded-normal light-logo" alt="logo" /> */}
              <h5 class="logo-title light-logo text-success ">Green</h5>
              <h5 class="logo-title light-logo text-secondary">-Mark</h5>
            </a>
            <div class="iq-menu-bt-sidebar">
              <i class="las la-bars wrapper-menu"></i>
            </div>
          </div>

          <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <nav class="iq-sidebar-menu ">
              <ul id="iq-sidebar-toggle" class="iq-menu">
                <li class="">
                  <a href="/dashboard" class="svg-icon">
                    <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span class="ml-4">Dashboards</span>
                  </a>
                </li>
                <li class="">
                  <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <span class="ml-4">Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="category" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addcategory">
                        <i class="las la-minus"></i><span>Add Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listcategory">
                        <i class="las la-minus"></i><span>List of Category</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                    </svg>
                    <span class="ml-4">Sub-Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addsubcategory">
                        <i class="las la-minus"></i><span>Add Sub-Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listsubcategory">
                        <i class="las la-minus"></i><span>Sub-Category List</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span class="ml-4">Products</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="product" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addproduct">
                        <i class="las la-minus"></i><span>Add Product</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listproduct">
                        <i class="las la-minus"></i><span>List of Products</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="/listorder" class="svg-icon">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                    </svg>
                    <span class="ml-4">Orders</span>
                  </a>
                </li>
                <li class="">
                  <a href="#updates" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M4 4h16v2H4z"></path>
                      <path d="M4 12h16v2H4z"></path>
                      <path d="M4 20h16v2H4z"></path>
                    </svg>
                    <span class="ml-4">Updates</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="updates" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/pricebook">
                        <i class="las la-minus"></i><span>Price Book</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/updateinventory">
                        <i class="las la-minus"></i><span>Update Inventory</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="active">
                  <a href="#people" class="collapsed" data-toggle="collapse" aria-expanded="true">
                    <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span class="ml-4">People</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="people" class="iq-submenu collapse show" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/customer">
                        <i class="las la-minus"></i><span>Customers</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/user">
                        <i class="las la-minus"></i><span>Users</span>
                      </a>
                    </li>
                    <li class="active">
                      <a href="/supplier">
                        <i class="las la-minus"></i><span>Suppliers</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <NavBar />
        <Header />
        <div className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 my-4">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                  <div>
                    <h4 className="m-0 p-0 text-primary my-2">Supplier List</h4>
                  </div>
                  <a href="/addsupplier" className="btn btn-primary m-0 add-list my-2"><i className="las la-plus mr-3"></i>Add Supplier</a>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="table-responsive rounded mb-3">
                  <table className="data-table table mb-0 tbl-server-info">
                    <thead className="bg-white text-uppercase">
                      <tr className="ligth ligth-data">
                        <th></th>
                        <th>Company Name</th>
                        <th>Name</th>
                        <th>E-Mail</th>
                        <th>Phone</th>
                        <th>City</th>
                        <th>Country</th>
                        <th>GST No.</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="ligth-body">
                      {suppliers.map(supplier => (
                        <tr key={supplier.id}>
                          <td>
                            <div className="checkbox d-inline-block">
                              <input type="checkbox" className="checkbox-input" id={`checkbox${supplier.id}`} />
                              <label htmlFor={`checkbox${supplier.id}`} className="mb-0"></label>
                            </div>
                          </td>
                          <td>{supplier.companyName}</td>
                          <td>{supplier.name}</td>
                          <td>{supplier.email}</td>
                          <td>{supplier.phone}</td>
                          <td>{supplier.city}</td>
                          <td>{supplier.country}</td>
                          <td>{supplier.gstNo}</td>
                          <td>
                            <div className="d-flex align-items-center list-action">
                              <a className="badge bg-success mr-2" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => handleEdit(supplier.id)}>
                                <i className="ri-pencil-line mr-0"></i>
                              </a>
                              <a className="badge bg-warning mr-2" data-toggle="tooltip" data-placement="top" title="Delete" onClick={() => handleDelete(supplier.id)}>
                                <i className="ri-delete-bin-line mr-0"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {editModalVisible &&
            <div className="modal" style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title text-primary">Edit Supplier</h5>
                    <button type="button" className="close" onClick={handleCloseEditModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="name">Company Name</label>
                        <input type="text" id="companyName" name="companyName" className="form-control"
                          value={supplierToEdit?.companyName || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" className="form-control"
                          value={supplierToEdit?.name || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" className="form-control"
                          value={supplierToEdit?.email || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input type="number" id="phone" name="phone" className="form-control"
                          value={supplierToEdit?.phone || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="gstNo">GST no.</label>
                        <input type="text" id="gstNo" name="gstNo" className="form-control"
                          value={supplierToEdit?.gstNo || ''} onChange={handleChange} />
                      </div>
                      <button type="submit" className="btn btn-primary">Save Changes</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          }
          <div className={`modal fade ${deleteModalVisible ? 'show' : ''}`} id="deleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="deleteConfirmationLabel" aria-hidden={!deleteModalVisible} style={{ display: deleteModalVisible ? 'block' : 'none' }}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-primary" id="deleteConfirmationLabel">Confirm Deletion</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseDeleteModal}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to delete this supplier?</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-warning" data-dismiss="modal" onClick={handleCloseDeleteModal}>Cancel</button>
                  <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Footer />
      </div>
    </>
  );
}

export default Supplier;
