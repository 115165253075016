import React, { useEffect, useState } from "react";
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';

const ListProduct = () => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [products, setProducts] = useState([]);
  const [alert, setAlert] = useState({ type: '', message: '', visible: false });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const showAlert = (type, message) => {
    setAlert({ type, message, visible: true });
    setTimeout(() => setAlert({ ...alert, visible: false }), 3000);
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch("http://206.189.134.171:8000/products", {
        method: 'GET',
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        setProducts(data);
        showAlert('success', 'Products loaded successfully');
      } else {
        showAlert('danger', 'Failed to load products');
      }
    } catch (error) {
      showAlert('danger', 'Error fetching products');
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch("http://206.189.134.171:8000/categories", {
        method: 'GET',
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      }
    } catch (error) {
      console.error('Error fetching categories', error);
    }
  };

  const fetchSubCategories = async (categoryName) => {
    try {
      const response = await fetch(`http://206.189.134.171:8000/subcategories`, {
        method: 'GET',
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        setSubCategories(data);
      }
    } catch (error) {
      console.error('Error fetching subcategories', error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchSubCategories();
  }, []);

  const handleEdit = (id) => {
    const product = products.find((product) => product.id === id);
    setProductToEdit(product);
    setEditModalVisible(true);
  };

  const handleUpdateProduct = (updatedProduct) => {
    const updatedProducts = products.map(product =>
      product.id === updatedProduct.id ? updatedProduct : product
    );
    setProducts(updatedProducts);
    setEditModalVisible(false);
    showAlert('success', 'Product updated successfully');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductToEdit({ ...productToEdit, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (productToEdit) {
      try {
        const response = await fetch(`http://206.189.134.171:8000/products/${encodeURIComponent(productToEdit.id)}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(productToEdit),
        });
        if (response.ok) {
          const updatedProduct = await response.json();
          handleUpdateProduct(updatedProduct);
        } else {
          showAlert('danger', 'Error updating product');
        }
      } catch (error) {
        showAlert('danger', 'Error updating product');
      }
    }
  };

  const handleDelete = (id) => {
    setProductToDelete(id);
    setDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`http://206.189.134.171:8000/products/${productToDelete}`, {
        method: 'DELETE',
        headers: { 'Accept': 'application/json' },
      });
      if (response.ok) {
        const updatedProducts = products.filter(product => product.id !== productToDelete);
        setProducts(updatedProducts);
        setDeleteModalVisible(false);
        showAlert('success', 'Product deleted successfully');
      } else {
        showAlert('danger', 'Error deleting product');
      }
    } catch (error) {
      showAlert('danger', 'Error deleting product');
    }
  };

  const filteredProducts = products.filter(products =>
    products.product_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <>
      <div id='wrapper'>
        <div class="iq-sidebar sidebar-default ">
          <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
            <a class="header-logo m-auto p-auto">
              {/* <img src="./assets/images/page-img/green-mark-logo.png" class="img-fluid rounded-normal light-logo" alt="logo" /> */}
              <h5 class="logo-title light-logo text-success ">Green</h5>
              <h5 class="logo-title light-logo text-secondary">-Mark</h5>
            </a>
            <div class="iq-menu-bt-sidebar">
              <i class="las la-bars wrapper-menu"></i>
            </div>
          </div>
          <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <nav class="iq-sidebar-menu ">
              <ul id="iq-sidebar-toggle" class="iq-menu">
                <li class="">
                  <a href="/dashboard" class="svg-icon">
                    <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span class="ml-4">Dashboards</span>
                  </a>
                </li>
                <li class="">
                  <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <span class="ml-4">Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="category" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addcategory">
                        <i class="las la-minus"></i><span>Add Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listcategory">
                        <i class="las la-minus"></i><span>List of Category</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polygon points="12 2 15 8 21 9 16 14 17 21 12 18 7 21 8 14 3 9 9 8 12 2"></polygon>
                    </svg>
                    <span class="ml-4">Sub-Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addsubcategory">
                        <i class="las la-minus"></i><span>Add Sub-Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listsubcategory">
                        <i class="las la-minus"></i><span>Sub-Category List</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="active">
                  <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="true">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span class="ml-4">Products</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="product" class="iq-submenu collapse show" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addproduct">
                        <i class="las la-minus"></i><span>Add Product</span>
                      </a>
                    </li>
                    <li class="active">
                      <a href="/listproduct">
                        <i class="las la-minus"></i><span>List of Products</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="/listorder" class="svg-icon">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                    </svg>
                    <span class="ml-4">Orders</span>
                  </a>
                </li>
                <li class="">
                  <a href="#updates" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M4 4h16v2H4z"></path>
                      <path d="M4 12h16v2H4z"></path>
                      <path d="M4 20h16v2H4z"></path>
                    </svg>
                    <span class="ml-4">Updates</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="updates" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/pricebook">
                        <i class="las la-minus"></i><span>Price Book</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/updateinventory">
                        <i class="las la-minus"></i><span>Update Inventory</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#people" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span class="ml-4">People</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="people" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/customer">
                        <i class="las la-minus"></i><span>Customers</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/user">
                        <i class="las la-minus"></i><span>Users</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/supplier">
                        <i class="las la-minus"></i><span>Suppliers</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <NavBar />
        <Header />
        <div className="content-page">
          <div className="container-fluid add-form-list">
            <div className="row">
              <div className="col-lg-12 my-4">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div>
                    <h4 className="m-0 p-0 text-primary my-2">Product List</h4>
                  </div>
                  <a className="btn btn-primary m-0 add-list my-2" href="/addproduct">
                    <i className="las la-plus mr-3"></i>Add Product
                  </a>
                </div>
                <div className='row p-3 mt-2'>
                  <div className="col-md-8 input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="ri-search-line"></i>
                      </span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search by Product Name"
                      value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                  </div>
                  <div className="col-md-4">
                    <select id="itemsPerPage" className="custom-select"
                      value={itemsPerPage} onChange={handleItemsPerPageChange} >
                      <option value="5">05 data / page</option>
                      <option value="10">10 data / page</option>
                      <option value="15">15 data / page</option>
                      <option value="20">20 data / page</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="table-responsive rounded mb-3">
                  <table className="data-table table mb-0 tbl-server-info">
                    <thead className="bg-white text-uppercase">
                      <tr className="ligth ligth-data">
                        <th>Product ID</th>
                        <th>Product Image</th>
                        <th>Product Name</th>
                        <th>Product Code</th>
                        <th>Price</th>
                        <th>Unit</th>
                        <th>SKU Code</th>
                        <th>Category Name</th>
                        <th>Sub-Category Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="ligth-body">
                      {currentProducts.length > 0 ? (
                        currentProducts.map((product) => (
                          <tr key={product.id}>
                            <td>{product.id}</td>
                            <td>
                              <img
                                src={`data:image/png;base64,${product.product_image}`}
                                alt={product.product_name}
                                className="img-fluid rounded avatar-50"
                              />
                            </td>
                            <td>{product.product_name}</td>
                            <td>{product.product_code}</td>
                            <td>₹ {product.price}</td>
                            <td>{product.unit}</td>
                            <td>{product.sku}</td>
                            <td>{product.category_name}</td>
                            <td>{product.sub_category_name}</td>
                            <td>
                              <div className="d-flex align-items-center list-action">
                                <a className="badge bg-warning mr-2" data-toggle="tooltip" data-placement="top"
                                  title="Edit" onClick={() => handleEdit(product.id)} >
                                  <i className="ri-pencil-line mr-0"></i>
                                </a>
                                <a className="badge bg-danger mr-2" data-toggle="tooltip" data-placement="top"
                                  title="Delete" onClick={() => handleDelete(product.id)} >
                                  <i className="ri-delete-bin-line mr-0"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9" className="text-center">No products found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
          {editModalVisible && (
            <div className="modal pt-5 mt-5" style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title text-orange">Edit Product</h5>
                    <button type="button" className="close" onClick={() => setEditModalVisible(false)}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ overflow: 'auto', maxHeight: '48vh' }}>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="product_name">Product Name</label>
                        <input type="text" id="product_name" name="product_name"
                          className="form-control" value={productToEdit?.product_name || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="product_name">Product Code</label>
                        <input type="text" id="product_code" name="product_code"
                          className="form-control" value={productToEdit?.product_code || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="price">Price</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-default">₹</span>
                          </div>
                          <input type="number" id="price" name="price" className="form-control"
                            value={productToEdit?.price || ''} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="unit">Unit</label>
                        <input type="text" id="unit" name="unit" className="form-control"
                          value={productToEdit?.unit || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="sku">SKU Code</label>
                        <input type="text" id="sku" name="sku"
                          className="form-control" value={productToEdit?.sku || ''} onChange={handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="category_name">Category Name</label>
                        <select id="category_name" name="category_name" className="custom-select" value={productToEdit.category_name} onChange={handleChange}>
                          {categories.map(category => (
                            <option key={category.id} value={category.category_name}>
                              {category.category_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="sub_category_name">Sub-Category Name</label>
                        <select id="sub_category_name" name="sub_category_name" className="custom-select" value={productToEdit.sub_category_name} onChange={handleChange}>
                          {subCategories.map(subCategory => (
                            <option key={subCategory.id} value={subCategory.sub_category_name}>
                              {subCategory.sub_category_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="modal-footer p-0 pt-3">
                        <button type="button" className="btn btn-secondary" onClick={() => setEditModalVisible(false)}>Cancel</button>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {deleteModalVisible && (
            <div className="modal" style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title text-primary">Confirm Deletion</h5>
                    <button type="button" className="close" onClick={() => setDeleteModalVisible(false)}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to delete this <b className="text-dark">Product</b> ?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => setDeleteModalVisible(false)}>Cancel</button>
                    <button type="button" className="btn btn-danger ml-2" onClick={handleConfirmDelete}>Delete</button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex flex-wrap justify-content-end mx-5">
            <div className="p-3">
              <button className="badge badge-secondary mr-1" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                <i className="fas fa-arrow-left"></i>
              </button>
              <button className="badge badge-secondary ml-1" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} >
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>
            <div className="p-3">
              Page {currentPage} of {totalPages}
            </div>
          </div>
          {alert.visible && (
            <div className={`alert alert-${alert.type} border-dark`} role="alert">
              <div className="iq-alert-icon text-danger">
                <i className={`ri-${alert.type === 'success' ? 'check-line' : 'information-line'}`}></i>
              </div>
              <div className="iq-alert-text text-orange">{alert.message}</div>
            </div>
          )}
        </div >
      </div >
      <Footer />
    </>
  );
}

export default ListProduct;
